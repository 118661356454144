import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { SharedService } from 'src/app/services/shared.service';
import { QrCodeService } from 'src/app/services/qr-code.service';
import { NodeComponent } from '../node/node.component';

@Component({
  selector: 'app-bbt-node',
  templateUrl: './bbt-node.component.html',
  styleUrls: ['../node/node.component.css', './bbt-node.component.css']
})
export class BbtNodeComponent extends NodeComponent {

  constructor(protected route: ActivatedRoute, protected router: Router,
    protected sharedService: SharedService, protected localizeService: LocalizeRouterService,
    public qrcodeService: QrCodeService) {
    super(route, router, sharedService, localizeService);
  }

  get prefix(): string {
    return "bbt";
  }

}
