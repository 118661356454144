import {
  Injectable
} from '@angular/core';
import {
  BehaviorSubject
} from 'rxjs';
import {
  environment
} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PolicyService {
  public policyMsg = new BehaviorSubject(this.policyAccepted);

  set policyAccepted(value) {
    if (value) {
      localStorage.setItem("policy", JSON.stringify({
        accept: true,
        version: environment.policyVersion
      }));
    } else {
      localStorage.removeItem("policy");
      localStorage.setItem("policy", JSON.stringify({
        accept: false,
        version: environment.policyVersion
      }));
    }
    this.policyMsg.next(localStorage.getItem("policy"));

  }

  get policyAccepted() {
    return localStorage.getItem("policy");
  }

  constructor() {}

  validatePolicy(callingComponent = "false"){
    // console.log("validating policy...")
    // console.log("Calling Component: " + callingComponent);
    let storagePolicy = JSON.parse(localStorage.getItem("policy"));
    // console.log("Validation If Result: " + (storagePolicy != null && storagePolicy.accept == true && storagePolicy.version == environment.policyVersion))
    if (storagePolicy != null && storagePolicy.accept == true && storagePolicy.version == environment.policyVersion)
      return true;
    else return false;
  }

  checkPolicyChoise(callingComponent = "false"){
    // console.log("validating policy...")
    // console.log("Calling Component: " + callingComponent);
    let storagePolicy = JSON.parse(localStorage.getItem("policy"));
    console.log("Validation If Result: " + (storagePolicy != null && storagePolicy.accept == true && storagePolicy.version == environment.policyVersion))
    if (storagePolicy != null && storagePolicy.version == environment.policyVersion)
      return true;
    else return false;
  }

}
