<header>
    <!-----------------------------------------------------------
			-- ESA Header module v2
			-- Date: 23-10-2020
			------------------------------------------------------------>
    <section id="esa-header" class="esa-header">
        <div class="esa-header__left">
            <button id="esa-menu" class="esa-header__toggle" (click)="showMenu()">
                <img class="esa-header__icon" src="assets/header/ESA_Menu.svg" alt="ESA menu toggle">
            </button>
            <!--button id="esa-search" class="esa-header__toggle">
        <img class="esa-header__icon" src="assets/header/ESA_Search.svg" alt="ESA search toggle">
      </button-->
            <!--button id="esa-login" class="esa-header__toggle">
        <img class="esa-header__icon" src="assets/header/ESA_Login.svg" alt="ESA login toggle">
      </button-->
            <!--button id="esa-language" class="esa-header__toggle">
        <img class="esa-header__icon" src="assets/header/ESA_Language.svg" alt="ESA language toggle">
      </button-->
            <img class="esa-header__title" src="assets/header/ESA_Title.svg" alt="ESA title">
        </div>
        <div class="esa-header__right">
            <a class="esa-header__link" (click)="qrcodeService.setUrl('https://www.esa.int')" target="_blank">
                <img class="esa-header__logo" src="assets/header/ESA_Logo.svg" alt="ESA logo">
            </a>
        </div>
    </section>
    <!-- End of module ----------------------------------------->
</header>
<nav>
    <div class="toggle close-toggle" (click)="hideMenu()">
        <img src="../../../assets/icons/close-menu.svg">
    </div>
    <div class="container">
        <div class="row">
            <div class="offset-by-two eight columns">
                <h1 (click)="goToHomeVideo()"><img [src]="'header.title-img' | translate" alt="Discover Esa"></h1>
                <p>{{'header.title' | translate}}</p>
                <div class="nodes">
                    <div class="node node-welcome" (click)="goToWelcomeVideo()">
                        <div class="node-img">
                            <img src="../../../assets/images/josef-welcome.png">
                        </div>
                        <h6 class="welcome-message" [innerHTML]="'header.welcome-message' | translate"></h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="twelve columns">

                <div class="nav-vt ">
                    <div class="vtcontent-top">
                        <h4>ESA 360°</h4>
                        <div class="vtcontent-top-img"><img src="assets/images/vt/vtmain-ico.png" alt=""></div>

                    </div>
                    <div class="vtcontent-bottom">
                        <h5>{{'header.select-vt' | translate}}</h5>
                        <div class="vtcontent-bottom-icons">
                            <a class="image-holder" [routerLink]="['/vt/eac' | localize]">
                                <div class="overlay"></div>
                                <img src="assets/images/vt/Esa_eac.svg" alt="">
                            </a>
                            <a class="image-holder" [routerLink]="['/vt/ecsat' | localize]">
                                <div class="overlay"></div><img src="assets/images/vt/Esa_ecsat.svg" alt="">
                            </a>
                            <a class="image-holder" [routerLink]="['/vt/esac' | localize]">
                                <div class="overlay"></div> <img src="assets/images/vt/Esa_esac.svg" alt="">
                            </a>
                            <a class="image-holder" [routerLink]="['/vt/esec' | localize]">
                                <div class="overlay"></div> <img src="assets/images/vt/Esa_esec.svg" alt="">
                            </a>
                            <a class="image-holder" [routerLink]="['/vt/esoc' | localize]">
                                <div class="overlay"></div> <img src="assets/images/vt/Esa_esoc.svg" alt="">
                            </a>
                            <a class="image-holder" [routerLink]="['/vt/esrin' | localize]">
                                <div class="overlay"></div> <img src="assets/images/vt/Esa_esrin.svg" alt="">
                            </a>
                            <a class="image-holder" [routerLink]="['/vt/estec' | localize]">
                                <div class="overlay"></div> <img src="assets/images/vt/Esa_estec.svg" alt="">
                            </a>
                            <!-- <a class="image-holder" [routerLink]="['/vt/esahq' | localize]">
                                <div class="overlay"></div> <img src="assets/images/vt/Esa_hq.svg" alt="">
                            </a> -->
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        <!-- row -->
        <!-- <div class="row row-pre-title">
            <div class="twelve columns">
                <h3>Select your journey and discover ESA</h3>
            </div>
        </div> -->
        <!-- row -->
        <div class="row row-path">
            <div class="twelve columns">
                <h4 class="path-title path-title-enlight-yellow" (click)="goToVideo('bbt',0)">
                    <img src="../../../assets/icons/esa-arrow-enlight-yellow.svg" alt="right arrow">
                    <span [innerHTML]="'header.bbt-title' | translate"></span>
                </h4>
                <div class="nodes nodes-sem nodes-enlight-yellow">
                    <div class="node node-0" (click)="goToVideo('bbt',0)">
                        <div class="node-img">
                            <img src="../../../assets/images/single-nodes/bbt/0.png">
                        </div>
                        <h6>{{'header.bbt-node-0' | translate}}</h6>
                    </div>
                    <div class="node node-1" (click)="goToVideo('bbt',1)">
                        <div class="node-img">
                            <img src="../../../assets/images/single-nodes/bbt/1.png">
                        </div>
                        <h6>{{'header.bbt-node-1' | translate}}</h6>
                    </div>
                    <div class="node node-2" (click)="goToVideo('bbt',2)">
                        <div class="node-img">
                            <img src="../../../assets/images/single-nodes/bbt/2.png">
                        </div>
                        <h6>{{'header.bbt-node-2' | translate}}</h6>
                    </div>
                    <div class="node node-3" (click)="goToVideo('bbt',3)">
                        <div class="node-img">
                            <img src="../../../assets/images/single-nodes/bbt/3.png">
                        </div>
                        <h6>{{'header.bbt-node-3' | translate}}</h6>
                    </div>
                    <div class="node node-4" (click)="goToVideo('bbt',4)">
                        <div class="node-img">
                            <img src="../../../assets/images/single-nodes/bbt/4.png">
                        </div>
                        <h6>{{'header.bbt-node-4' | translate}}</h6>
                    </div>
                </div>
            </div>
        </div>
        <!-- row -->
        <div class="row row-path">
            <div class="twelve columns">
                <h4 class="path-title path-title-excite-red">
                    <img src="../../../assets/icons/esa-arrow-excite-red.svg" alt="right arrow">
                    <span [innerHTML]="'header.uou-title' | translate"></span>
                </h4>
                <div class="nodes nodes-sem nodes-excite-red">
                    <div class="node node-0" (click)="goToVideo('uou',0)">
                        <div class="node-img">
                            <img src="../../../assets/images/single-nodes/uou/0.png">
                        </div>
                        <h6>{{'header.uou-node-0' | translate}}</h6>
                    </div>
                    <div class="node node-1" (click)="goToVideo('uou',1)">
                        <div class="node-img">
                            <img src="../../../assets/images/single-nodes/uou/1.png">
                        </div>
                        <h6>{{'header.uou-node-1' | translate}}</h6>
                    </div>
                    <div class="node node-2" (click)="goToVideo('uou',2)">
                        <div class="node-img">
                            <img src="../../../assets/images/single-nodes/uou/2.png">
                        </div>
                        <h6>{{'header.uou-node-2' | translate}}</h6>
                    </div>
                    <div class="node node-3" (click)="goToVideo('uou',3)">
                        <div class="node-img">
                            <img src="../../../assets/images/single-nodes/uou/3.png">
                        </div>
                        <h6>{{'header.uou-node-3' | translate}}</h6>
                    </div>
                    <div class="node node-4" (click)="goToVideo('uou',4)">
                        <div class="node-img">
                            <img src="../../../assets/images/single-nodes/uou/4.png">
                        </div>
                        <h6>{{'header.uou-node-4' | translate}}</h6>
                    </div>
                    <div class="node node-5" (click)="goToVideo('uou',5)">
                        <div class="node-img">
                            <img src="../../../assets/images/single-nodes/uou/5.png">
                        </div>
                        <h6>{{'header.uou-node-5' | translate}}</h6>
                    </div>
                </div>
            </div>
        </div>
        <!-- row -->
        <div class="row row-path">
            <div class="twelve columns">
                <h4 class="path-title path-title-excite-red">
                    <img src="../../../assets/icons/esa-arrow-excite-red.svg" alt="right arrow">
                    <span  [innerHTML]="'header.sem-title' | translate"></span>
                </h4>
                <div class="nodes nodes-sem nodes-excite-red">
                    <div class="node node-0" (click)="goToVideo('sem',0)">
                        <div class="node-img">
                            <img src="../../../assets/images/single-nodes/sem/0.png">
                        </div>
                        <h6>{{'header.sem-node-0' | translate}}</h6>
                    </div>
                    <div class="node node-1" (click)="goToVideo('sem',1)">
                        <div class="node-img">
                            <img src="../../../assets/images/single-nodes/sem/1.png">
                        </div>
                        <h6>{{'header.sem-node-1' | translate}}</h6>
                    </div>
                    <div class="node node-2" (click)="goToVideo('sem',2)">
                        <div class="node-img">
                            <img src="../../../assets/images/single-nodes/sem/2.png">
                        </div>
                        <h6>{{'header.sem-node-2' | translate}}</h6>
                    </div>
                    <div class="node node-3" (click)="goToVideo('sem',3)">
                        <div class="node-img">
                            <img src="../../../assets/images/single-nodes/sem/3.png">
                        </div>
                        <h6>{{'header.sem-node-3' | translate}}</h6>
                    </div>
                    <div class="node node-4" (click)="goToVideo('sem',4)">
                        <div class="node-img">
                            <img src="../../../assets/images/single-nodes/sem/4.png">
                        </div>
                        <h6>{{'header.sem-node-4' | translate}}</h6>
                    </div>
                    <div class="node node-5" (click)="goToVideo('sem',5)">
                        <div class="node-img">
                            <img src="../../../assets/images/single-nodes/sem/5.png">
                        </div>
                        <h6>{{'header.sem-node-5' | translate}}</h6>
                    </div>
                    <div class="node node-6" (click)="goToVideo('sem',6)">
                        <div class="node-img">
                            <img src="../../../assets/images/single-nodes/sem/6.png">
                        </div>
                        <h6>{{'header.sem-node-6' | translate}}</h6>
                    </div>
                </div>
            </div>
        </div>
        <!-- row -->
        <div class="row row-path">
            <div class="twelve columns">
                <h4 class="path-title path-title-pure-teal">
                    <img src="../../../assets/icons/esa-arrow-pure-teal.svg" alt="right arrow">
                    <span [innerHTML]="'header.poe-title' | translate"></span>
                </h4>
                <div class="nodes nodes-sem nodes-pure-teal">
                    <div class="node node-0" (click)="goToVideo('poe',0)">
                        <div class="node-img">
                            <img src="../../../assets/images/single-nodes/poe/0.png">
                        </div>
                        <h6>{{'header.poe-node-0' | translate}}</h6>
                    </div>
                    <div class="node node-1" (click)="goToVideo('poe',1)">
                        <div class="node-img">
                            <img src="../../../assets/images/single-nodes/poe/1.png">
                        </div>
                        <h6>{{'header.poe-node-1' | translate}}</h6>
                    </div>
                    <div class="node node-2" (click)="goToVideo('poe',2)">
                        <div class="node-img">
                            <img src="../../../assets/images/single-nodes/poe/2.png">
                        </div>
                        <h6>{{'header.poe-node-2' | translate}}</h6>
                    </div>
                    <div class="node node-3" (click)="goToVideo('poe',3)">
                        <div class="node-img">
                            <img src="../../../assets/images/single-nodes/poe/3.png">
                        </div>
                        <h6>{{'header.poe-node-3' | translate}}</h6>
                    </div>
                    <div class="node node-4" (click)="goToVideo('poe',4)">
                        <div class="node-img">
                            <img src="../../../assets/images/single-nodes/poe/4.png">
                        </div>
                        <h6>{{'header.poe-node-4' | translate}}</h6>
                    </div>
                </div>
            </div>
        </div>
        <!-- row -->
        <div class="row row-path">
            <div class="twelve columns">
            <h4 class="path-title path-title-pure-teal">
                <img src="../../../assets/icons/esa-arrow-pure-teal.svg" alt="right arrow" >
                <span [innerHTML]="'header.ile-title' | translate"></span>
            </h4>
                <div class="nodes nodes-sem nodes-pure-teal">
                    <div class="node node-0" (click)="goToVideo('ile',0)">
                        <div class="node-img">
                            <img src="../../../assets/images/single-nodes/ile/0.png">
                        </div>
                        <h6>{{'header.ile-node-0' | translate}}</h6>
                    </div>
                    <div class="node node-1" (click)="goToVideo('ile',1)">
                        <div class="node-img">
                            <img src="../../../assets/images/single-nodes/ile/1.png">
                        </div>
                        <h6 class="long-word">{{'header.ile-node-1' | translate}}</h6>
                    </div>
                    <div class="node node-2" (click)="goToVideo('ile',2)">
                        <div class="node-img">
                            <img src="../../../assets/images/single-nodes/ile/2.png">
                        </div>
                        <h6>{{'header.ile-node-2' | translate}}</h6>
                    </div>
                    <div class="node node-3" (click)="goToVideo('ile',3)">
                        <div class="node-img">
                            <img src="../../../assets/images/single-nodes/ile/3.png">
                        </div>
                        <h6>{{'header.ile-node-3' | translate}}</h6>
                    </div>
                    <div class="node node-4" (click)="goToVideo('ile',4)">
                        <div class="node-img">
                            <img src="../../../assets/images/single-nodes/ile/4.png">
                        </div>
                        <h6>{{'header.ile-node-4' | translate}}</h6>
                    </div>
                </div>
            </div>
        </div>
        <!-- row -->
        <div class="row row-path row-path-last">
            <div class="twelve columns">
                <h4 class="path-title path-title-trusty-azure">
                    <img src="../../../assets/icons/esa-arrow-trusty-azure.svg" alt="right arrow">
                    <span  [innerHTML]="'header.ssa-title' | translate"></span>
                </h4>
                <div class="nodes nodes-sem nodes-trusty-azure">
                    <div class="node node-0" (click)="goToVideo('ssa',0)">
                        <div class="node-img">
                            <img src="../../../assets/images/single-nodes/ssa/0.png">
                        </div>
                        <h6>{{'header.ssa-node-0' | translate}}</h6>
                    </div>
                    <div class="node node-1" (click)="goToVideo('ssa',1)">
                        <div class="node-img">
                            <img src="../../../assets/images/single-nodes/ssa/1.png">
                        </div>
                        <h6>{{'header.ssa-node-1' | translate}}</h6>
                    </div>
                    <div class="node node-2" (click)="goToVideo('ssa',2)">
                        <div class="node-img">
                            <img src="../../../assets/images/single-nodes/ssa/2.png">
                        </div>
                        <h6>{{'header.ssa-node-2' | translate}}</h6>
                    </div>
                    <div class="node node-3" (click)="goToVideo('ssa',3)">
                        <div class="node-img">
                            <img src="../../../assets/images/single-nodes/ssa/3.png">
                        </div>
                        <h6>{{'header.ssa-node-3' | translate}}</h6>
                    </div>
                    <div class="node node-4" (click)="goToVideo('ssa',4)">
                        <div class="node-img">
                            <img src="../../../assets/images/single-nodes/ssa/4.png">
                        </div>
                        <h6>{{'header.ssa-node-4' | translate}}</h6>
                    </div>
                </div>
            </div>
        </div>
        <!-- row -->
        <div class="row">
            <div class="twelve columns">
                <h3 class="">{{'header.join-in' | translate}}</h3>
                <div class="extra">
                    <div class="extra-trigger is-extra-trigger" [routerLink]="['/is/eo' | localize]">
                        <div class="extra-label is-extra-label">{{'header.join-in' | translate}}</div>
                        <h6 class="extra-title is-extra-title">{{'header.eo-title' | translate}}</h6>
                    </div>
                    <div class="extra-trigger is-extra-trigger" [routerLink]="['/is/helmet' | localize]">
                        <div class="extra-label is-extra-label">{{'header.join-in' | translate}}</div>
                        <h6 class="extra-title is-extra-title">{{'header.helmet-title' | translate}}</h6>
                    </div>
                    <div class="extra-trigger is-extra-trigger" [routerLink]="['/is/ariane6' | localize]">
                        <div class="extra-label is-extra-label">{{'header.join-in' | translate}}</div>
                        <h6 class="extra-title is-extra-title">{{'header.ariane6-title' | translate}}</h6>
                    </div>
                </div>
            </div>
        </div>
        <!-- row -->
        <div class="row privacy-menu">
            <div class="offset-by-two eight columns">
                <h5 class="">{{'header.privacy-title' | translate}}</h5>
                <p>{{'header.privacy-text-1' | translate}}
                    <a (click)="hideMenu()" [routerLink]="['/policy' | localize]">{{'header.privacy-text-2' | translate}}</a>
                </p>
            </div>
        </div>
        <!-- row -->
    </div>
</nav>