import { Component, OnInit } from '@angular/core';
import { QrCodeService } from 'src/app/services/qr-code.service';

@Component({
  selector: 'app-policy-page',
  templateUrl: './policy-page.component.html',
  styleUrls: ['./policy-page.component.css']
})
export class PolicyPageComponent implements OnInit {

  constructor(public qrcodeService: QrCodeService) { }

  ngOnInit(): void {
  }

}
