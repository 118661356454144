<div class="wrapper">
    <div class="ariane6-main">
        <div #modalElement class="modal-element-overlay show">
            <div class="modal">
                <p class="main-text" [innerHTML]="'ariane6-is.main-text' | translate">
                </p>
                <div class="input-element-div">
                    <button class="element-btn" (click)="elementClick('giraffe')" >{{'ariane6-is.giraffe' | translate}}</button>
                    <button class="element-btn" (click)="elementClick('elephant')">{{'ariane6-is.elephant' | translate}}</button>
                    <!-- <button class="element-btn" (click)="calculate()" data-element="pine">PINE</button> -->
                    <button class="element-btn" (click)="elementClick('you')">{{'ariane6-is.you' | translate}}</button>
                </div>
    
            </div>
        </div>
        <div #modalInput class="modal-overlay">
            <div class="modal">
                <p class="main-text" [innerHTML]="'ariane6-is.2nd-main-text'">
                </p>
                <div class="input-height-div">
                    <div class="input-height">
                        <p class="your-height-text">{{'ariane6-is.your-height-text' | translate}}</p>
                        <input type="number" #yourHeightElement id="your-height" name="your-height" class="your-height-number" placeholder="0.00 m." min="0.30" max="2.50" step="0.01">
                        <div class="empty-space">&nbsp;</div>
                        <button #calculateElement (click)="clickCalculate()" class="calculate-btn disabled" id="calculateButton" disabled="disabled">{{'ariane6-is.calculate' | translate}}</button>
                    </div>
                </div>

            </div>
        </div>
        <div class="arianne6-container">
            <div class="men-div" #menElement>
                <img #singleManElement class="single-man element-img" style="height: 50px; width: auto;" src="assets/images/ariane6-is/single-man.png ">
                <img #elephantElement class="elephant element-img" style="height: 20px; width: auto;" src="assets/images/ariane6-is/elephant.png ">
                <img #pineElement class="pine element-img" style="height: 50px; width: auto;" src="assets/images/ariane6-is/pine.png ">
                <img #giraffeElement class="giraffe element-img" style="height: 50px; width: auto;" src="assets/images/ariane6-is/giraffe.png ">
            </div>
        </div>
        <div class="bottom-grey-bar ">
            <div class="grey-bar-div">
                <div class="elements-number"><strong #totalElement id="totElements">0,0</strong> of <span #elementName id="elementName">elements</span></div>
                <!-- <div class="men-number"> <strong #totalElement id="totPersons">0,0</strong> of you</div> -->
                <div class="mt-number"><strong>62</strong> m.</div>
                <button class="restart-btn modal-launch" (click)="restart()" disabled="disabled">{{'ariane6-is.restart' | translate}}</button>
            </div>
        </div>
    </div>



    
</div>