export class Constants {

    static EVENT_ON_VIDEO_ENDED = "EVENT_ON_VIDEO_ENDED";
    static EVENT_ON_ROUTER_OUTLET = "EVENT_ON_ROUTER_OUTLET";
    static EVENT_ON_HIDE_LOADER = "EVENT_ON_HIDE_LOADER";
    static EVENT_ON_SHOW_LOADER = "EVENT_ON_SHOW_LOADER";
    static EVENT_ON_READY_LOADER = "EVENT_ON_READY_LOADER";


    static SELECTOR_SEM_ACTIVE_NODES = ["#sem-active-node-0", "#sem-active-node-1", "#sem-active-node-2",
        "#sem-active-node-3", "#sem-active-node-4", "#sem-active-node-5", "#sem-active-node-6"];
}