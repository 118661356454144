import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PolicyService } from 'src/app/services/policy.service';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.css']
})
export class PolicyComponent implements OnInit {
  @Input ('isInPolicyBar') isInPolicyBar: boolean = false;
  policyValidated :boolean = false;
  private policySubscription :Subscription;
  constructor(private policyService: PolicyService) { 
  }

  ngOnInit(): void {
    this.policySubscription = this.policyService.policyMsg.subscribe(result => this.policyValidated = this.policyService.validatePolicy("PolicyComponent"));
  }

  toggleAcceptance(accepted){
    this.policyService.policyAccepted = accepted;
  }

  ngOnDestroy(){
    try {
      this.policySubscription? this.policySubscription.unsubscribe() : null;
    } catch (error) {
      console.log(error);
    }
  }
}
