<div class="modal modal-loader" id="loader">
    <div class="modal-contents">
        <h4>{{'loader.title' | translate}}</h4>
        <div class="tutorial">
            <span class="tutorial-icon"><img src="../../../assets/icons/drag.svg"></span> {{'loader.and' | translate}} <span class="tutorial-icon"><img src="../../../assets/icons/zoom-in-out.svg"></span>
            <span [innerHTML]="'loader.subtitle' | translate"></span>
        </div>
        <div #progressButton class="progress-button" (click)="hide()">
            <span #progressBar class="progress-bar">&nbsp;</span>
            <span class="label">{{'loader.button' | translate}}</span>
        </div>
    </div>
</div>