import { SecurityContext } from '@angular/core';
import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { PageComponent } from '../page/page.component';

@Component({
    selector: 'app-vt',
    templateUrl: './vt.component.html',
    styleUrls: ['./vt.component.css']
})
export class VtComponent extends PageComponent {

    vt;
    url;

    constructor(private route: ActivatedRoute, private sanitizer: DomSanitizer) {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.route.params.subscribe((params: Params) => {
            document.querySelector("body").classList.remove("show-menu");
            this.vt = params.vt;
            this.url = this.sanitizer.bypassSecurityTrustResourceUrl("assets/vt/" + this.vt + "/" + this.vt.toUpperCase() + ".html");
        });
    }

    get prefix(): string {
        return "vt";
    }

}
