<div class="video-wrapper">
    <video 
        *ngIf="hasMultiLanguage !== ''; else noMultiLangVideo"
        poster="assets/i18n/{{hasMultiLanguage}}/video/{{params.prefix}}/p{{params.index}}.jpg"
        class="fullscreen" (ended)="onEnded()" controls muted autoplay playsinline id="video">
        <source src="assets/i18n/{{hasMultiLanguage}}/video/{{params.prefix}}/{{params.index}}_{{hasMultiLanguage}}.mp4">
    </video>
    <ng-template #noMultiLangVideo>
        <video 
            poster="assets/video/{{params.prefix}}/p{{params.index}}.jpg"
            class="fullscreen" (ended)="onEnded()" controls muted autoplay playsinline id="video">
            <source src="assets/video/{{params.prefix}}/{{params.index}}.mp4">
        </video>
    </ng-template>
</div>