import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LocalizeParser, LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.css'],
})
export class LanguageSelectorComponent implements OnInit {
  @Output() hideSelector: EventEmitter<boolean> = new EventEmitter<boolean>();
  langList = [];
  currentLang = null;
  error = false;
  success = false;
  constructor(private translate: TranslateService, private router: Router, private localize: LocalizeRouterService) {
    this.langList = this.localize.parser.locales;
    this.currentLang = this.localize.parser.currentLang;
  }

  ngOnInit(): void { }

  close() {
    this.success = false;
    this.error = false;
    this.hideSelector.emit(false);
  }

  changeLang(lang) {
    if (lang === this.currentLang) return;
    this.translate.use(lang).subscribe({
      next: () => {
        this.success = true;
        this.localize.changeLanguage(lang);
        this.currentLang = this.translate.currentLang;
      },
      error: () => this.error = true,
      complete: () => {
        setTimeout(() => {
          this.success = false;
          this.error = false;
        }, 4000);
      }
    });
  }
}
