import { Component, OnInit } from '@angular/core';
import { QrCodeService } from 'src/app/services/qr-code.service';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.css']
})
export class QrCodeComponent implements OnInit {

  qrUrl: any = null;

  constructor(private qrCodeService: QrCodeService) {
    this.qrCodeService.url.subscribe({
      next: (url) => this.qrUrl = url,
    });
  }

  ngOnInit(): void {
  }

  closeQrModal(){
    this.qrCodeService.resetUrl();
  }

}
