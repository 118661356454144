import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './components/home/home.component';
import { VideoComponent } from './components/video/video.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SwiperModule } from 'swiper/angular';
import { SemNodeComponent } from './components/sem-node/sem-node.component';
import { IleNodeComponent } from './components/ile-node/ile-node.component';
import { UouNodeComponent } from './components/uou-node/uou-node.component';
import { SsaNodeComponent } from './components/ssa-node/ssa-node.component';
import { PoeNodeComponent } from './components/poe-node/poe-node.component';
import { BbtNodeComponent } from './components/bbt-node/bbt-node.component';

// Translation modules
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

// QR Code module
import { QRCodeModule } from 'angularx-qrcode';


//Interactive Segment
import { EoIsComponent } from './components/eo-is/eo-is.component';
import { ActivatedComponentsDirective } from './directives/activated-components.directive';
import { NodeFactoryComponent } from './components/node-factory/node-factory.component';
import { LoaderComponent } from './components/loader/loader.component';
/* import { MatomoComponent } from './components/matomo/matomo.component';*/
import { HelmetIsComponent } from './components/helmet-is/helmet-is.component';
import { IsFactoryComponent } from './components/is-factory/is-factory.component';
import { Ariane6IsComponent } from './components/ariane6-is/ariane6-is.component';
import { VtComponent } from './components/vt/vt.component';
import { PolicyComponent } from './components/policy/policy.component';
import { PolicyPageComponent } from './components/policy-page/policy-page.component';
import { PolicyBarComponent } from './components/policy-bar/policy-bar.component';
import { VtUiComponent } from './components/vt-ui/vt-ui.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { QrCodeComponent } from './components/qr-code/qr-code.component';
import { NoRightClickDirective } from './directives/no-right-click.directive';

// Translate loader
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    VideoComponent,
    HeaderComponent,
    FooterComponent,
    SemNodeComponent,
    IleNodeComponent,
    UouNodeComponent,
    SsaNodeComponent,
    PoeNodeComponent,
    BbtNodeComponent,
    EoIsComponent,
    ActivatedComponentsDirective,
    NodeFactoryComponent,
    LoaderComponent,
    /*     MatomoComponent,*/
    HelmetIsComponent,
    IsFactoryComponent,
    Ariane6IsComponent,
    VtComponent,
    PolicyComponent,
    PolicyPageComponent,
    PolicyBarComponent,
    VtUiComponent,
    LanguageSelectorComponent,
    QrCodeComponent,
    NoRightClickDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SwiperModule,
    BrowserAnimationsModule,
    QRCodeModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      },
      defaultLanguage: 'en'
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
  //,schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
