import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export abstract class PageComponent implements OnInit {

  bodyElement: HTMLBodyElement;
  footerElement: HTMLElement;
  headerElement: HTMLElement;
  isReady = new BehaviorSubject<any>({});

  abstract get prefix(): string;

  ngOnInit(): void {
    document.querySelector('body').className = this.prefix;
    document.querySelector('footer').className = this.prefix;
    document.querySelector('header').className = this.prefix;
    this.bodyElement = document.getElementsByTagName("body")[0];
    this.footerElement = document.getElementsByTagName("footer")[0];
    this.headerElement = document.getElementsByTagName("header")[0];
  }

}