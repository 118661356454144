import { Directive } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Constants } from '../constants';
import { SharedService } from '../services/shared.service';

@Directive({
  selector: 'router-outlet'
})
export class ActivatedComponentsDirective {

  constructor(routerOutlet: RouterOutlet, sharedService: SharedService) {
    routerOutlet.activateEvents.pipe(
    ).subscribe(compInstance => {
      sharedService.subject.next({
        key: Constants.EVENT_ON_ROUTER_OUTLET,
        data: compInstance
      });
    })
  }
}