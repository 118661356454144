<div class="wrapper">
  <div class="main-helmet-container">
    <h3 class="main-text" #mainTextElement>{{'helmet-is.main-text' | translate}}</h3>
    <div class="video-div">
      <video #videoElement id="video" width="auto" height="auto" autoplay></video>
    </div>
    <div class="helmet-div">
      <img #helmetElement src="assets/images/helmet-is/helmet.png" id="helmet" crossorigin="anonymous">
    </div>
    <div class="buttons">
      <!--div class="buttons-div"-->
        <div class="selector shoot-selector" *ngIf="!showSaveButton" (click)="snap()">
          <img src="assets/images/helmet-is/shoot-btn.png">
          <br>
          {{'helmet-is.take-photo' | translate}}
        </div>
        <div class="selector save-selector" *ngIf="showSaveButton" (click)="download()">
          <img src="assets/images/helmet-is/save-btn.png">
          <br>
          {{'helmet-is.save' | translate}}
        </div>
    </div>
    <div id="preview-div" #previewElement class="hide" (click)="preview()">
      <canvas #canvasElement id="cameraCanvas" width="500px" height="500px"></canvas>
    </div>
    <div id="downloader"></div>
  </div>
</div>