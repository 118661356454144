import {
  AfterViewInit,
  Component,
  ViewChild
} from '@angular/core';
import {
  PageComponent
} from '../page/page.component';
import * as $ from 'jquery';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-ariane6-is',
  templateUrl: './ariane6-is.component.html',
  styleUrls: ['./ariane6-is.component.css']
})
export class Ariane6IsComponent extends PageComponent implements AfterViewInit {

  @ViewChild("singleManElement") singleManElement;
  @ViewChild("pineElement") pineElement;
  @ViewChild("elephantElement") elephantElement;
  @ViewChild("giraffeElement") giraffeElement;
  @ViewChild("yourHeightElement") yourHeightElement;
  @ViewChild("calculateElement") calculateElement;
  @ViewChild("menElement") menElement;
  @ViewChild("totalElement") totalElement;
  @ViewChild("elementName") elementName;
  @ViewChild("modalElement") modalElement;
  @ViewChild("modalInput") modalInput;

  arianeHeight = 6200;
  interval;
  elements = {
    elephant: {
      height: 400,
      baseElem: $('changeMe'),
      name: 'elephant',
      showedName: 'elephants',
      translateIndex: 'elephants-label'
    },
    pine: {
      height: 2500,
      baseElem: $('changeMe'),
      name: 'pine',
      showedName: 'pines',
      translateIndex: 'pines-label'
    },
    giraffe: {
      height: 600,
      baseElem: $('changeMe'),
      name: 'giraffe',
      showedName: 'giraffes',
      translateIndex: 'giraffes-label'
    },
    you: {
      height: 30,
      baseElem: $('changeMe'),
      name: 'single-man',
      showedName: 'you',
      translateIndex: 'you-label'
    },
  }



  get prefix(): string {
    return "ariane6";
  }

  constructor( private translate: TranslateService) {
    super();
  }

  ngAfterViewInit(): void {
    this.elements.you.baseElem = $('.single-man.element-img');
    this.elements.elephant.baseElem = $('.elephant.element-img');
    this.elements.pine.baseElem = $('.pine.element-img');
    this.elements.giraffe.baseElem = $('.giraffe.element-img');
    $('.men-div').html(''); // clear .men-div
    this.yourHeightElement.nativeElement.value = '';
    this.addListenerMulti(this.yourHeightElement.nativeElement, 'keyup mouseup input change click', (event) => {
      let value = parseFloat(this.yourHeightElement.nativeElement.value);
      if (!isNaN(value) && value <= 2.50 && value >= 0.30) {
        this.elements.you.height = value * 100;
        this.calculateElement.nativeElement.classList.remove('disabled');
        this.calculateElement.nativeElement.disabled = false;
      } else {
        this.calculateElement.nativeElement.classList.add('disabled');
        this.calculateElement.nativeElement.disabled = true;
      }
    });

  }

  addListenerMulti(el, s, fn) {
    s.split(' ').forEach(e => el.addEventListener(e, fn, false));
  }

  elementClick(elem) {
    let elemName = elem;

    setTimeout(() => {
      $('.modal-element-overlay').removeClass('show');
    }, 500);

    let element = this.elements[elemName];
    if (element && elemName != 'you') {
      this.calculate2(element);
    } else if (elemName === 'you') {
      this.showHeightInput();
    } else return;
  };

  // show height input modal
  showHeightInput() {
    $('.modal-overlay').addClass('show');
  }

  clickCalculate() {
    let height = Number($('#your-height').val()) * 100;
    if (height < 30 || height > 250)
      return false;
    this.elements.you.height = height;
    return this.calculate2(this.elements.you);
  }

  // calculate(elem) {
  //   // Check person height
  //   if (this.personHeight < 30 || this.personHeight > 250) {
  //     return;
  //   }

  //   // how many persons to reach ariane
  //   this.totPersons = (this.arianeHeight / this.personHeight).toFixed(1);

  //   // reset input and calculate button after calculate press
  //   setTimeout(() => {
  //     this.yourHeightElement.nativeElement.value = '';
  //     this.calculateElement.nativeElement.disabled = true;
  //     this.calculateElement.nativeElement.classList.add('disabled');
  //   }, 200);

  //   // if person has int or float value
  //   let totPersonsInt: number = parseInt(this.totPersons) + ((this.totPersons % 1 == 0) ? 0 : 1);

  //   // set height of single person image
  //   this.singleManElement.nativeElement.style.height = (this.menElement.nativeElement.clientHeight / this.totPersons) + "px";

  //   // clone single person image based on how much to fill column
  //   for (let index = 0; index < totPersonsInt; index++) {
  //     let clone = this.singleManElement.nativeElement.cloneNode();
  //     clone.style.display = 'none';
  //     this.menElement.nativeElement.append(clone);
  //   }

  //   this.modalElement.nativeElement.classList.remove('show');

  //   // show person images based on time
  //   let fadeInCounter = 0;
  //   let fadeInIncrease = parseInt((4000 / totPersonsInt) + "");

  //   document.querySelectorAll(".single-man").forEach((e: HTMLElement) => {
  //     setTimeout(() => {
  //       e.style.display = 'unset';
  //     }, fadeInCounter);
  //     fadeInCounter = fadeInCounter + fadeInIncrease;
  //   });

  //   // start counter incrase based on person height
  //   switch (true) {
  //     case (this.personHeight <= 50):
  //       this.interval = setInterval(() => {
  //         this.counterIncrease(0.75);
  //       }, 10);
  //       break;
  //     case (50 < this.personHeight && this.personHeight <= 80):
  //       this.interval = setInterval(() => {
  //         this.counterIncrease(0.40);
  //       }, 10);
  //       break;
  //     case (80 < this.personHeight && this.personHeight <= 100):
  //       this.interval = setInterval(() => {
  //         this.counterIncrease(0.30);
  //       }, 10);
  //       break;
  //     case (100 < this.personHeight && this.personHeight <= 120):
  //       this.interval = setInterval(() => {
  //         this.counterIncrease(0.25);
  //       }, 10);
  //       break;
  //     case (120 < this.personHeight && this.personHeight <= 150):
  //       this.interval = setInterval(() => {
  //         this.counterIncrease(0.20);
  //       }, 10);
  //       break;
  //     case (150 < this.personHeight && this.personHeight <= 170):
  //       this.interval = setInterval(() => {
  //         this.counterIncrease(0.17);
  //       }, 10);
  //       break;
  //     case (170 < this.personHeight && this.personHeight <= 200):
  //       this.interval = setInterval(() => {
  //         this.counterIncrease(0.15);
  //       }, 10);
  //       break;
  //     case (200 < this.personHeight && this.personHeight <= 251):
  //       this.interval = setInterval(() => {
  //         this.counterIncrease(0.15);
  //       }, 10);
  //       break;
  //     default:
  //       break;
  //   }
  // }

  calculate2(elem) {
    let baseElement = elem.baseElem;
    let totElements = (this.arianeHeight / elem.height).toFixed(1);

    let columnHeight = $('.men-div').height(); // column div height
    let totElementsInt = 0; // int number of element images
    $('.modal-element-overlay').removeClass('show');
    $('.modal-overlay').removeClass('show');
    this.translate.get(elem.translateIndex).subscribe((res: string) => {
      $('#elementName').text(res);
      //=> 'hello world'
  });
    $('#elementName').text(elem.showedName);

    // if element has int or float value
    if (parseFloat(totElements) % 1 == 0)
      totElementsInt = parseInt(totElements);
    else
      totElementsInt = parseInt(totElements) + 1;

    // set height of single element image
    baseElement.css("height", (columnHeight / parseFloat(totElements)));
    // clone single element image based on how much to fill column
    for (let index = 0; index < totElementsInt; index++) {
      let clone = baseElement.clone();
      // clone.css("height", (columnHeight / parseFloat(totElements)) + 'px');
      $('.men-div').append(clone);
    }

    $('.men-div > img').hide();

    let fadeInCounter = 0;
    let fadeInIncrease = 4000 / totElementsInt;
    $('.men-div > img').each(function (event) {
      let element = this;
      setTimeout(() => {
        $(element).show();
      }, fadeInCounter);
      fadeInCounter = fadeInCounter + fadeInIncrease;
    });
    switch (true) {
      case (elem.height <= 50):
        this.interval = setInterval(() => {
          this.counterIncrease(0.75, totElements);
        }, 10);
        break;
      case (50 < elem.height && elem.height <= 80):
        this.interval = setInterval(() => {
          this.counterIncrease(0.40, totElements);
        }, 10);
        break;
      case (80 < elem.height && elem.height <= 100):
        this.interval = setInterval(() => {
          this.counterIncrease(0.30, totElements);
        }, 10);
        break;
      case (100 < elem.height && elem.height <= 120):
        this.interval = setInterval(() => {
          this.counterIncrease(0.25, totElements);
        }, 10);
        break;
      case (120 < elem.height && elem.height <= 150):
        this.interval = setInterval(() => {
          this.counterIncrease(0.20, totElements);
        }, 10);
        break;
      case (150 < elem.height && elem.height <= 170):
        this.interval = setInterval(() => {
          this.counterIncrease(0.17, totElements);
        }, 10);
        break;
      case (170 < elem.height && elem.height <= 200):
        this.interval = setInterval(() => {
          this.counterIncrease(0.15, totElements);
        }, 10);
        break;
      case (200 < elem.height && elem.height <= 251):
        this.interval = setInterval(() => {
          this.counterIncrease(0.15, totElements);
        }, 10);
        break;
      case (elem.height === 400): // elephant case
        this.interval = setInterval(() => {
          this.counterIncrease(0.2, totElements);
        }, 45);
        break;
      case (elem.height === 600): // giraffe case
        this.interval = setInterval(() => {
          this.counterIncrease(0.2, totElements);
        }, 60);
        break;

      default:
        break;

    }
  }



  stopIncrease(total) {
    $('#totElements').text(total);
    clearInterval(this.interval);
    $('.restart-btn').removeAttr('disabled');
  }

  // increase number based on setInterval
  counterIncrease(increase, total) {
    let value = parseFloat($('#totElements').text());
    value = parseFloat(value + increase);
    $('#totElements').text(value.toFixed(1));
    if (value >= total) {
      this.stopIncrease(total);
    }
  }

  restart() {
    $('.modal-element-overlay').addClass('show');
    $('.modal-overlay').removeClass('show');
    setTimeout(() => {
      $('#elementName').text('elements');
      $('.men-div').html('');
      $('#totElements').text('0.0');
      $('.restart-btn').attr('disabled', 'true');
      $('#your-height').val('');
    }, 400);
  }

}
