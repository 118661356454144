<div class="swiper-container swiper-container-{{prefix}}">
  <div class="swiper-wrapper">
    <div class="swiper-slide trusty-azure-node">
      <main>
        <div class="main-wrapper">
          <section class="items">
            <div class="item item-ssa first-node-video-trigger" [ngClass]="isVideoSeen(0) ? 'item-ssa-replay' : 'item-ssa-play'">
              <h4><span class="sequence-number">0</span>{{'ssa-node.subtitle' | translate}}</h4>
            </div>
            <div class="item item-node-1" (click)="navigateToInternalNode(1)">
              <h4><span class="sequence-number">1</span>{{'ssa-node.slide-1-title' | translate}}</h4>
            </div>
            <div class="item item-node-2" (click)="navigateToInternalNode(2)">
              <h4><span class="sequence-number">2</span>{{'ssa-node.slide-2-title' | translate}}</h4>
            </div>
            <div class="item item-node-3" (click)="navigateToInternalNode(3)">
              <h4><span class="sequence-number">3</span>{{'ssa-node.slide-3-title' | translate}}</h4>
            </div>
            <div class="item item-node-4" (click)="navigateToInternalNode(4)">
              <h4><span class="sequence-number">4</span>{{'ssa-node.slide-4-title' | translate}}</h4>
            </div>
          </section>
        </div>
      </main>
    </div>
    <!-- node -->
    <div class="swiper-slide trusty-azure-node mixed-with-excite-red">
      <main>
        <div class="main-wrapper" [ngClass]="isVideoSeen(1) ? 'hide-video' : 'show-video'">
          <section class="heading">
            <div class="heading-titles heading-titles-1">
              <h3 (click)="navigateToInternalNode(0)">{{'ssa-node.subtitle' | translate}}</h3>
              <h1>{{'ssa-node.slide-1-title' | translate}}</h1>
            </div>
          </section>
          <section class="video">
            <div class="video-close">
              <img src="assets/icons/close-white.svg">
            </div>
            <div class="video-trigger" style="background-image: url(../../../assets/video/ssa/p1.jpg)">
              <img class="video-play" src="assets/images/single-nodes/commons/video-play-trusty-azure.svg">
            </div>
          </section>
          <section class="items">
            <div class="item item-find-out">
              <h3>{{'ssa-node.find-out-more' | translate}}</h3>
            </div>
            <div class="item item-reload">
              <h4 class="video-trigger">{{'ssa-node.replay-video' | translate}}</h4>
            </div>
            <div class="item item-video">
              <h4><a (click)="qrcodeService.setUrl('https://www.esa.int/ESA_Multimedia/Videos/2018/11/What_is_space_weather#.YAr4FFjT2_4.link')" target="_blank">{{'ssa-node.slide-1-what-is-the-weather' | translate}}</a></h4>
            </div>
            <!-- Deprecated -->
            <!-- <div class="item item-webpage">
              <h4><a (click)="qrcodeService.setUrl('https://swe.ssa.esa.int/sidc-S101c-federated')" target="_blank">Solar activity today</a></h4>
            </div> -->
            <div class="item item-video">
              <h4><a (click)="qrcodeService.setUrl('https://www.esa.int/ESA_Multimedia/Videos/2018/01/ESA_Lagrange_mission_animation#.YA63jd4NKxM.link')" target="_blank">{{'ssa-node.slide-1-lagrange-mission' | translate}}</a></h4>
            </div>
          </section>
          <section class="extra">
            <div class="extra-trigger vt-extra-trigger" [routerLink]="['/vt/esoc' | localize]">
              <div class="extra-label vt-extra-label">{{'ssa-node.vitual-tour' | translate}}</div>
              <h6 class="extra-title vt-extra-title">ESA ESOC</h6>
            </div>
            <div class="extra-trigger vt-extra-trigger" [routerLink]="['/vt/esec' | localize]">
              <div class="extra-label vt-extra-label">{{'ssa-node.vitual-tour' | translate}}</div>
              <h6 class="extra-title vt-extra-title">ESA ESEC</h6>
            </div>
            <div class="extra-trigger excite-red-extra-trigger" (click)="navigateToOtherNodes('uou', 1)">
              <div class="extra-label excite-red-label">{{'ssa-node.understanding-our-universe' | translate}}</div>
              <h6 class="extra-title excite-red-title">{{'ssa-node.the-sun-and-the-earth' | translate}}</h6>
            </div>
          </section>
        </div>
      </main>
    </div>
    <!-- node -->
    <div class="swiper-slide trusty-azure-node mixed-with-enlight-yellow">
      <main>
        <div class="main-wrapper" [ngClass]="isVideoSeen(2) ? 'hide-video' : 'show-video'">
          <section class="heading">
            <div class="heading-titles heading-titles-2">
              <h3 (click)="navigateToInternalNode(0)">{{'ssa-node.subtitle' | translate}}</h3>
              <h1>{{'ssa-node.slide-2-title' | translate}}</h1>
            </div>
          </section>
          <section class="video">
            <div class="video-close">
              <img src="assets/icons/close-white.svg">
            </div>
            <div class="video-trigger" style="background-image: url(../../../assets/video/ssa/p1.jpg)">
              <img class="video-play" src="assets/images/single-nodes/commons/video-play-trusty-azure.svg">
            </div>
          </section>
          <section class="items">
            <div class="item item-find-out">
              <h3>{{'ssa-node.find-out-more' | translate}}</h3>
            </div>
            <div class="item item-reload">
              <h4 class="video-trigger">{{'ssa-node.replay-video' | translate}}</h4>
            </div>
            <div class="item item-webpage">
              <h4>
                <a (click)="qrcodeService.setUrl('https://www.esa.int/ESA_Multimedia/Videos/2018/07/Flyeye_the_bug-eyed_telescope_monitoring_our_skies#.YCoqxoaBgPk.link')" target="_blank">{{'ssa-node.slide-2-flyeye-telescope' | translate}}</a>
              </h4>
            </div>
            <div class="item item-video">
              <h4><a (click)="qrcodeService.setUrl('https://www.esa.int/ESA_Multimedia/Videos/2015/06/Asteroid_Day_-_ESA_experts_explain_the_nature_and_threat_of_asteroids#.YAr5srFnGJk.link')" target="_blank">{{'ssa-node.slide-2-esa-experts-explain' | translate}}</a></h4>
            </div>
            <div class="item item-video">
              <h4><a (click)="qrcodeService.setUrl('https://www.esa.int/ESA_Multimedia/Videos/2019/06/Hera_ESA_s_planetary_defence_mission#.YAr6SEx4b5I.link')" target="_blank">{{'ssa-node.slide-2-hera' | translate}}</a></h4>
            </div>
          </section>
          <section class="extra">
            <div class="extra-trigger vt-extra-trigger" [routerLink]="['/vt/esrin' | localize]">
              <div class="extra-label vt-extra-label">{{'ssa-node.vitual-tour' | translate}}</div>
              <h6 class="extra-title vt-extra-title">ESA ESRIN</h6>
            </div>
            <div class="extra-trigger enlight-yellow-extra-trigger" (click)="navigateToOtherNodes('bbt', 0)">
              <div class="extra-label enlight-yellow-label">{{'ssa-node.building-the-best-tools' | translate}}</div>
              <h6 class="extra-title enlight-yellow-title">{{'ssa-node.go-to-this-journey' | translate}}</h6>
            </div>
          </section>
        </div>
      </main>
    </div>
    <!-- node -->
    <div class="swiper-slide trusty-azure-node mixed-with-enlight-yellow">
      <main>
        <div class="main-wrapper" [ngClass]="isVideoSeen(3) ? 'hide-video' : 'show-video'">
          <section class="heading">
            <div class="heading-titles heading-titles-3">
              <h3 (click)="navigateToInternalNode(0)">{{'ssa-node.subtitle' | translate}}</h3>
              <h1>{{'ssa-node.slide-3-title' | translate}}</h1>
            </div>
          </section>
          <section class="video">
            <div class="video-close">
              <img src="assets/icons/close-white.svg">
            </div>
            <div class="video-trigger" style="background-image: url(../../../assets/video/ssa/p3.jpg)">
              <img class="video-play" src="assets/images/single-nodes/commons/video-play-trusty-azure.svg">
            </div>
          </section>
          <section class="items">
            <div class="item item-find-out">
              <h3>{{'ssa-node.find-out-more' | translate}}</h3>
            </div>
            <div class="item item-reload">
              <h4 class="video-trigger">{{'ssa-node.replay-video' | translate}}</h4>
            </div>
            <div class="item item-webpage item-enlight-yellow">
              <h4><a (click)="qrcodeService.setUrl('http://www.esa.int/Enabling_Support/Operations')" target="_blank">{{'ssa-node.slide-3-spacecraft' | translate}}</a></h4>
            </div>
            <div class="item item-webpage item-enlight-yellow">
              <h4><a (click)="qrcodeService.setUrl('https://blogs.esa.int/rocketscience/')" target="_blank">{{'ssa-node.slide-3-rocket-science-blog' | translate}}</a></h4>
            </div>
          </section>
          <section class="extra">
            <div class="extra-trigger vt-extra-trigger" [routerLink]="['/vt/esoc' | localize]">
              <div class="extra-label vt-extra-label">{{'ssa-node.vitual-tour' | translate}}</div>
              <h6 class="extra-title vt-extra-title">ESA ESOC</h6>
            </div>
            <div class="extra-trigger enlight-yellow-extra-trigger" (click)="navigateToOtherNodes('bbt', 4)">
              <div class="extra-label enlight-yellow-label">{{'ssa-node.building-the-best-tools' | translate}}</div>
              <h6 class="extra-title enlight-yellow-title">{{'ssa-node.flying-in-space' | translate}}</h6>
            </div>
          </section>
        </div>
      </main>
    </div>
    <!-- node -->
    <div class="swiper-slide trusty-azure-node">
      <main>
        <div class="main-wrapper" [ngClass]="isVideoSeen(4) ? 'hide-video' : 'show-video'">
          <section class="heading">
            <div class="heading-titles heading-titles-4">
              <h3 (click)="navigateToInternalNode(0)">{{'ssa-node.subtitle' | translate}}</h3>
              <h1>{{'ssa-node.slide-4-title' | translate}}</h1>
            </div>
          </section>
          <section class="video">
            <div class="video-close">
              <img src="assets/icons/close-white.svg">
            </div>
            <div class="video-trigger" style="background-image: url(../../../assets/video/ssa/p4.jpg)">
              <img class="video-play" src="assets/images/single-nodes/commons/video-play-trusty-azure.svg">
            </div>
          </section>
          <section class="items">
            <div class="item item-find-out">
              <h3>{{'ssa-node.find-out-more' | translate}}</h3>
            </div>
            <div class="item item-reload">
              <h4 class="video-trigger">{{'ssa-node.replay-video' | translate}}</h4>
            </div>
            <div class="item item-video">
              <h6>{{'ssa-node.meet-the-experts' | translate}}</h6>
              <h4><a (click)="qrcodeService.setUrl('https://www.esa.int/ESA_Multimedia/Videos/2020/06/Meet_the_Experts_Cleaning_up_space#.X7-8sHP3F2Q.link')" target="_blank">{{'ssa-node.slide-4-cleaning-up-space' | translate}}</a></h4>
            </div>
            <div class="item item-webpage">
              <h4><a (click)="qrcodeService.setUrl('https://blogs.esa.int/cleanspace/')" target="_blank">{{'ssa-node.slide-4-clean-space-blog' | translate}}</a></h4>
            </div>
            <div class="item item-webpage">
              <h4><a (click)="qrcodeService.setUrl('http://www.esa.int/Safety_Security/Space_Debris/About_space_debris')" target="_blank">{{'ssa-node.slide-4-space-debris' | translate}}</a></h4>
            </div>
          </section>
          <section class="extra">
            <div class="extra-trigger vt-extra-trigger" [routerLink]="['/vt/esahq' | localize]">
              <div class="extra-label vt-extra-label">{{'ssa-node.vitual-tour' | translate}}</div>
              <h6 class="extra-title vt-extra-title">ESA HQ</h6>
            </div>
            <div class="extra-trigger vt-extra-trigger" [routerLink]="['/vt/esoc' | localize]">
              <div class="extra-label vt-extra-label">{{'ssa-node.vitual-tour' | translate}}</div>
              <h6 class="extra-title vt-extra-title">ESA ESOC</h6>
            </div>
            <div class="extra-trigger vt-extra-trigger" [routerLink]="['/vt/estec' | localize]">
              <div class="extra-label vt-extra-label">{{'ssa-node.vitual-tour' | translate}}</div>
              <h6 class="extra-title vt-extra-title">ESA ESTEC</h6>
            </div>
            <div class="extra-trigger excite-red-extra-trigger" (click)="navigateToOtherNodes('sem', 0)">
              <div class="extra-label excite-red-label">{{'ssa-node.sending-europe-to-the-moon' | translate}}</div>
              <h6 class="extra-title excite-red-title">{{'ssa-node.go-to-this-journey' | translate}}</h6>
            </div>
          </section>
        </div>
      </main>
    </div>
    <!-- node -->
  </div>
  <div class="swiper-button-prev">
    <img src="assets/icons/left-white.svg">
  </div>
  <div class="swiper-button-next">
    <img src="assets/icons/right-white.svg">
  </div>
</div>
