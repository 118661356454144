import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { IsComponent } from '../is/is.component';

@Component({
  selector: 'app-helmet-is',
  templateUrl: './helmet-is.component.html',
  styleUrls: ['./helmet-is.component.css']
})
export class HelmetIsComponent extends IsComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild("previewElement") previewElement: ElementRef;
  @ViewChild("mainTextElement") mainTextElement: ElementRef;
  @ViewChild("shootElement") shootElement: ElementRef;
  @ViewChild("saveElement") saveElement: ElementRef;
  @ViewChild("canvasElement") canvasElement: ElementRef;
  @ViewChild("videoElement") videoElement: ElementRef;
  @ViewChild("helmetElement") helmetElement: ElementRef;

  showSaveButton = false;
  isShooted = false;
  localStream;

  constructor(private deviceService: DeviceDetectorService) {
    super();
  }


  ngOnDestroy(): void {
    this.disableCam();
  }

  ngOnInit() {
    super.ngOnInit();
    setTimeout(() => {
      this.mainTextElement.nativeElement.style.display = 'none';
    }, 4000);
  }

  ngAfterViewInit(): void {
    if (navigator && navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      this.enableCam();
    }
  }

  enableCam() {
    navigator.mediaDevices.getUserMedia({
      video: true,
      audio: false
    })
      .then((stream, _self = this) => {
        _self.videoElement.nativeElement.srcObject = stream;
        _self.videoElement.nativeElement.play();
      })
      .catch((err) => {
        console.log(err.name + ": " + err.message);
      });
  }

  disableCam() {
    try {
      let videoNativeElement = this.videoElement.nativeElement;
      videoNativeElement.srcObject.getTracks()[0].stop();
      videoNativeElement.pause();
      videoNativeElement.src = "";
    } catch(e) {
      console.error(e);
    }
  }

  preview() {
    if (this.showSaveButton) {
      this.previewElement.nativeElement.classList.add('hide');
      this.showSaveButton = false;
    }
  }

  snap() {
    let m = (this.deviceService.orientation == "portrait") ? 1.2 : 1.5;

    let videoNativeElement = this.videoElement.nativeElement;
    let canvasNativeElement = this.canvasElement.nativeElement;
    let context = canvasNativeElement.getContext('2d');

    context.drawImage(
      videoNativeElement,
      0,
      0,
      videoNativeElement.videoWidth,
      videoNativeElement.videoHeight,
      canvasNativeElement.width / 2 - (videoNativeElement.videoWidth / m) / 2,
      canvasNativeElement.height / 2 - (videoNativeElement.videoHeight / m) / 2,
      videoNativeElement.videoWidth / m,
      videoNativeElement.videoHeight / m);

    setTimeout((_self = this) => {
      context.drawImage(_self.helmetElement.nativeElement, -175, -170, 850, 850);
    }, 100);

    this.previewElement.nativeElement.classList.remove('hide');
    this.showSaveButton = true;
  }

  download() {
    let image = this.canvasElement.nativeElement.toDataURL("image/jpg");
    let anchor = document.createElement('a');
    anchor.setAttribute('download', 'AstronautMe.jpg');
    anchor.setAttribute('href', image);
    anchor.click();
    anchor.remove();
  }

  get prefix(): string {
    return "helmet-is";
  }
}