<footer>

  <div class="tools tools-zoom" *ngIf="type=='zoom'">
    <div class="tool full-screen-trigger" *ngIf="isDesktop" (click)="fullScreen()">
      <img *ngIf="!isFullScreen" class="full-screen-icon" src="assets/icons/enlarge-screen-white.svg">
      <img *ngIf="isFullScreen" class="reduce-screen-icon" src="assets/icons/reduce-screen-white.svg">
    </div>
    <div class="tool zoom-in-trigger" (click)="zoomIn()">
      <img src="assets/icons/zoom-in-white.svg">
    </div>
    <div class="tool zoom-out-trigger" (click)="zoomOut()">
      <img class="zoom-out-icon" src="assets/icons/zoom-out-white.svg">
      <img class="home-icon" src="assets/icons/home-white.svg">
    </div>
    <div class="tool ">
      <div (click)="showLanguageSelectorComponent = !showLanguageSelectorComponent;">
        <img src="assets/icons/globe-white.svg">
      </div>
    </div>
    <app-language-selector (hideSelector)="closeLanguageSelector($event)" *ngIf="showLanguageSelectorComponent"></app-language-selector>
  </div>
  <div class="tools tools-zoom" *ngIf="type=='node'">
    <div class="tool full-screen-trigger" *ngIf="isDesktop" (click)="fullScreen()">
      <img *ngIf="!isFullScreen" class="full-screen-icon" src="assets/icons/enlarge-screen-white.svg">
      <img *ngIf="isFullScreen" class="reduce-screen-icon" src="assets/icons/reduce-screen-white.svg">
    </div>
    <div class="tool home-trigger" (click)="navigateToHome()">
      <img src="assets/icons/home-white.svg">
    </div>
  </div>
  <div class="tools tools-video" *ngIf="type=='video'">
    <div *ngIf="!homeVideo" class="tool close-trigger" (click)="navigateToNode()">
      <img src="assets/icons/close-white.svg">
    </div>
    <div *ngIf="homeVideo" class="tool close-trigger" (click)="navigateToHome()">
      <img src="assets/icons/close-white.svg">
    </div>
    <div class="tool full-screen-trigger" *ngIf="isDesktop" (click)="fullScreen()">
      <img *ngIf="!isFullScreen" class="full-screen-icon" src="assets/icons/enlarge-screen-white.svg">
      <img *ngIf="isFullScreen" class="reduce-screen-icon" src="assets/icons/reduce-screen-white.svg">
    </div>
    <div *ngIf="!homeVideo" class="tool home-trigger" (click)="navigateToHome()">
      <img src="assets/icons/home-white.svg">
    </div>
  </div>
  <div class="tools tools-video tools-video-is" *ngIf="type=='is'">
    <div class="tool close-trigger" (click)="navigateBack()">
      <img src="assets/icons/close-white.svg">
    </div>
    <div class="tool full-screen-trigger" *ngIf="isDesktop" (click)="fullScreen()">
      <img *ngIf="!isFullScreen" class="full-screen-icon" src="assets/icons/enlarge-screen-white.svg">
      <img *ngIf="isFullScreen" class="reduce-screen-icon" src="assets/icons/reduce-screen-white.svg">
    </div>
    <div class="tool home-trigger" (click)="navigateToHome()">
      <img src="assets/icons/home-white.svg">
    </div>
  </div>
  <div class="tools tools-video tools-video-is tools-video-is-ariane6" *ngIf="type=='is-ariane6'">
    <div class="tool close-trigger" (click)="navigateBack()">
      <img src="assets/icons/close-white.svg">
    </div>
    <div class="tool full-screen-trigger" *ngIf="isDesktop" (click)="fullScreen()">
      <img *ngIf="!isFullScreen" class="full-screen-icon" src="assets/icons/enlarge-screen-white.svg">
      <img *ngIf="isFullScreen" class="reduce-screen-icon" src="assets/icons/reduce-screen-white.svg">
    </div>
    <div class="tool home-trigger" (click)="navigateToHome()">
      <img src="assets/icons/home-white.svg">
    </div>
  </div>
  <div class="tools tools-video tools-video-vt" *ngIf="type == 'vt'">
    <div class="tool close-trigger" (click)="navigateBack()">
      <img src="assets/icons/close-white.svg">
    </div>
    <div class="tool full-screen-trigger" *ngIf="isDesktop" (click)="fullScreen()">
      <img *ngIf="!isFullScreen" class="full-screen-icon" src="assets/icons/enlarge-screen-white.svg">
      <img *ngIf="isFullScreen" class="reduce-screen-icon" src="assets/icons/reduce-screen-white.svg">
    </div>
    <div class="tool home-trigger" (click)="navigateToHome()">
      <img src="assets/icons/home-white.svg">
    </div>
  </div>
  <div class="tools tools-zoom" *ngIf="type=='policy'">
    <div class="tool close-trigger" (click)="navigateBack()">
      <img src="assets/icons/close-white.svg">
    </div>
    <div class="tool home-trigger" (click)="navigateToHome()">
      <img src="assets/icons/home-white.svg">
    </div>
  </div>
</footer>