<div class="policy-bar" *ngIf="!checkPolicyChoise">
    <div class="policy-notice">
        <p>{{'policy-bar.text-1' | translate}}
            <a [routerLink]="['/policy']">
                {{'policy-bar.text-2' | translate}}
            </a>
        </p>

    </div>

    <app-policy [isInPolicyBar]="true"></app-policy>
</div>