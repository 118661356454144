import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from 'src/app/constants';
import { SharedService } from 'src/app/services/shared.service';
import { VideoComponent } from '../video/video.component';
import { VtComponent } from '../vt/vt.component';
import { HomeComponent } from '../home/home.component';
import { NodeFactoryComponent } from '../node-factory/node-factory.component';
import { IsFactoryComponent } from '../is-factory/is-factory.component';
import * as d3 from "d3";
import { DeviceDetectorService } from 'ngx-device-detector';
import fscreen from 'fscreen';
import { Ariane6IsComponent } from '../ariane6-is/ariane6-is.component';
import { EoIsComponent } from '../eo-is/eo-is.component';
import { HelmetIsComponent } from '../helmet-is/helmet-is.component';
import { PolicyPageComponent } from '../policy-page/policy-page.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  index: number;
  type: string = "";
  showLanguageSelectorComponent: boolean = false;

  constructor(private deviceService: DeviceDetectorService, private sharedService: SharedService) {
    this.sharedService.subject.subscribe(event => {
      if (event && event.key === Constants.EVENT_ON_VIDEO_ENDED) {
        if (this.sharedService.homeVideo) {
          this.navigateToHome();
        } else {
          this.navigateToNode();
        }
      }

      if (event && event.key === Constants.EVENT_ON_ROUTER_OUTLET) {
        //HOME
        if (event.data instanceof HomeComponent) {
          this.type = "zoom";
        }
        //VIDEO
        if (event.data instanceof VideoComponent) {
          this.type = "video";
        }
        //NODE
        if (event.data instanceof NodeFactoryComponent) {
          this.type = "node";
        }
        //IS
        if (event.data instanceof EoIsComponent || event.data instanceof HelmetIsComponent) {
          this.type = "is";
        }
        if (event.data instanceof Ariane6IsComponent) {
          this.type = "is-ariane6";
        }
        //VT
        if (event.data instanceof VtComponent) {
          this.type = "vt";
        }
        //POLICY
        if (event.data instanceof PolicyPageComponent) {
          this.type = "policy";
        }
      }
    });
  }

  ngOnInit(): void {
  }

  zoomIn() {
    d3.select("svg").node().zoomIn();
  }

  zoomOut() {
    if (this.type === 'zoom') {
      d3.select("svg").node().zoomOut();
    } else {
      this.navigateToHome();
    }
  }

  get bodyElement() {
    return document.getElementsByTagName("body")[0];
  }

  navigateToNode() {
    if (!this.homeVideo) {
      let array = window.location.href.split('/');
      let index = array.pop();
      let path = array.pop();
      this.sharedService.fadeOutAndNavigateTo(["/node", path, index]);
    }
  }

  navigateToHome() {
    this.sharedService.fadeOutAndNavigateTo(["/"]);
  }

  navigateBack() {
    history.back();
  }

  get homeVideo() {
    return this.sharedService.homeVideo;
  }

  get isDesktop() {
    return this.deviceService.isDesktop();
  }



  fullScreen() {
    if (fscreen.fullscreenEnabled) {
      if (fscreen.fullscreenElement !== null) {
        fscreen.exitFullscreen();
      } else {
        fscreen.requestFullscreen(document.body);
      }
    }
  }

  get isFullScreen() {
    return (fscreen.fullscreenElement !== null);
  }

  closeLanguageSelector(event){
    this.showLanguageSelectorComponent = false;
  }

}