import { Component } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Ariane6IsComponent } from '../ariane6-is/ariane6-is.component';
import { EoIsComponent } from '../eo-is/eo-is.component';
import { HelmetIsComponent } from '../helmet-is/helmet-is.component';

@Component({
  selector: 'app-is-factory',
  template: `
    <ng-container *ngComponentOutlet="content"></ng-container>
  `
})
export class IsFactoryComponent {

  content;

  constructor(private route: ActivatedRoute) {
    this.route.params.subscribe((params: Params) => {
      switch (params.prefix) {
        case 'eo':
          this.content = EoIsComponent;
          break;
        case 'helmet':
          this.content = HelmetIsComponent;
          break;
        case 'ariane6':
          this.content = Ariane6IsComponent;
          break;
        default:
      }
    });
  }
}
