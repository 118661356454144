export const selectors = {
    emptyPaths: ["#bbt-empty-path", "#ssa-empty-path", "#uou-empty-path", "#poe-empty-path", "#ile-empty-path", "#sem-empty-path"],
    activePaths: ["#bbt-active-path", "#ssa-active-path", "#uou-active-path", "#poe-active-path", "#ile-active-path", "#sem-active-path"],
    //red
    sem: {
        start: "#sem-empty-node-start",
        empty: [ "#sem-empty-node-0", "#sem-empty-node-1",
            "#sem-empty-node-2", "#sem-empty-node-3", "#sem-empty-node-4",
            "#sem-empty-node-5", "#sem-empty-node-6"],
        active: ["#sem-active-node-start", "#sem-active-node-0", "#sem-active-node-1",
            "#sem-active-node-2", "#sem-active-node-3", "#sem-active-node-4",
            "#sem-active-node-5", "#sem-active-node-6"],
        path: ["#sem-empty-path"]
    },
    //green
    poe: {
        start: "#poe-empty-node-start",
        empty: [, "#poe-empty-node-0", "#poe-empty-node-1",
            "#poe-empty-node-2", "#poe-empty-node-3", "#poe-empty-node-4"],
        active: ["#poe-active-node-start", "#poe-active-node-0", "#poe-active-node-1",
            "#poe-active-node-2", "#poe-active-node-3", "#poe-active-node-4"],
        path: ["#poe-empty-path"]
    },
    //green
    ile: {
        start: "#ile-empty-node-start",
        empty: ["#ile-empty-node-start", "#ile-empty-node-0", "#ile-empty-node-1",
            "#ile-empty-node-2", "#ile-empty-node-3", "#ile-empty-node-4",
            "#ile-empty-node-5", "#ile-empty-node-6"],
        active: ["#ile-active-node-start", "#ile-active-node-0", "#ile-active-node-1",
            "#ile-active-node-2", "#ile-active-node-3", "#ile-active-node-4",
            "#ile-active-node-5", "#ile-active-node-6"],
        path: ["#ile-empty-path"]
    },
    //blue
    ssa: {
        start: "#ssa-empty-node-start",
        empty: ["#ssa-empty-node-start", "#ssa-empty-node-0", "#ssa-empty-node-1",
            "#ssa-empty-node-2", "#ssa-empty-node-3", "#ssa-empty-node-4"],
        active: ["#ssa-active-node-start", "#ssa-active-node-0", "#ssa-active-node-1",
            "#ssa-active-node-2", "#ssa-active-node-3", "#ssa-active-node-4"],
        path: ["#ssa-empty-path"]
    },
    //red
    uou: {
        start: "#uou-empty-node-start",
        empty: ["#uou-empty-node-start", "#uou-empty-node-0", "#uou-empty-node-1",
            "#uou-empty-node-2", "#uou-empty-node-3", "#uou-empty-node-4",
            "#uou-empty-node-5"],
        active: ["#uou-active-node-start", "#uou-active-node-0", "#uou-active-node-1",
            "#uou-active-node-2", "#uou-active-node-3", "#uou-active-node-4",
            "#uou-active-node-5"],
        path: ["#uou-empty-path"]
    },
    //yellow
    bbt: {
        start: "#bbt-empty-node-start",
        empty: ["#bbt-empty-node-start", "#bbt-empty-node-0", "#bbt-empty-node-1",
            "#bbt-empty-node-2", "#bbt-empty-node-3", "#bbt-empty-node-4"],
        active: ["#bbt-active-node-start", "#bbt-active-node-0", "#bbt-active-node-1",
            "#bbt-active-node-2", "#bbt-active-node-3", "#bbt-active-node-4"],
        path: ["#bbt-empty-path"]
    }
}