<div class="swiper-container swiper-container-{{prefix}}">
    <div class="swiper-wrapper">
        <div class="swiper-slide excite-red-node">
            <main>
                <div class="main-wrapper">
                    <section class="items">
                        <div class="item item-uou first-node-video-trigger" [ngClass]="isVideoSeen(0) ? 'item-uou-replay' : 'item-uou-play'">
                            <h4><span class="sequence-number">0</span>{{'uou-node.subtitle' | translate}}</h4>
                        </div>
                        <div class="item item-node-1" (click)="navigateToInternalNode(1)">
                            <h4><span class="sequence-number">1</span>{{'uou-node.slide-1-title' | translate}}</h4>
                        </div>
                        <div class="item item-node-2" (click)="navigateToInternalNode(2)">
                            <h4><span class="sequence-number">2</span>{{'uou-node.slide-2-title' | translate}}</h4>
                        </div>
                        <div class="item item-node-3" (click)="navigateToInternalNode(3)">
                            <h4><span class="sequence-number">3</span>{{'uou-node.slide-3-title' | translate}}</h4>
                        </div>
                        <div class="item item-node-4" (click)="navigateToInternalNode(4)">
                            <h4><span class="sequence-number">4</span>{{'uou-node.slide-4-title' | translate}}</h4>
                        </div>
                        <div class="item item-node-5" (click)="navigateToInternalNode(5)">
                            <h4><span class="sequence-number">5</span>{{'uou-node.slide-5-title' | translate}}</h4>
                        </div>
                    </section>
                </div>
            </main>
        </div>
        <!-- node -->
        <div class="swiper-slide excite-red-node mixed-with-trusty-azure">
            <main>
                <div class="main-wrapper" [ngClass]="isVideoSeen(1) ? 'hide-video' : 'show-video'">
                    <section class="heading">
                        <div class="heading-titles heading-titles-1">
                            <h3 (click)="navigateToInternalNode(0)">{{'uou-node.subtitle' | translate}}</h3>
                            <h1>{{'uou-node.slide-1-title' | translate}}</h1>
                        </div>
                    </section>
                    <section class="video">
                        <div class="video-close">
                            <img src="assets/icons/close-white.svg">
                        </div>
                        <div class="video-trigger" style="background-image: url(../../../assets/video/uou/p1.jpg)">
                            <img class="video-play" src="assets/images/single-nodes/commons/video-play-excite-red.svg">
                        </div>
                    </section>
                    <section class="items">
                        <div class="item item-find-out">
                            <h3>{{'uou-node.find-out-more' | translate}}</h3>
                        </div>
                        <div class="item item-reload">
                            <h4 class="video-trigger">{{'uou-node.replay-video' | translate}}</h4>
                        </div>
                        <div class="item item-video">
                            <h4><a (click)="qrcodeService.setUrl('https://www.esa.int/ESA_Multimedia/Videos/2019/01/A_taste_of_astronomy_at_ESA#.YAm2VidD4nw.link')" target="_blank">{{'uou-node.slide-1-a-taste-of' | translate}}</a></h4>
                        </div>
                        <div class="item item-webpage">
                            <h4><a (click)="qrcodeService.setUrl('http://www.esa.int/Science_Exploration/Space_Science/Solar_Orbiter')" target="_blank">{{'uou-node.slide-1-solar-orbiter' | translate}}</a></h4>
                        </div>
                        <div class="item item-video">
                            <h4><a (click)="qrcodeService.setUrl('https://www.esa.int/ESA_Multimedia/Videos/2018/07/What_is_an_eclipse#.YAqx0mf57lA.link')" target="_blank">{{'uou-node.slide-1-eclipses' | translate}}</a></h4>
                        </div>
                        <div class="item item-video">
                            <h4><a (click)="qrcodeService.setUrl('https://www.esa.int/esatv/Videos/2020/02/ESA_s_missions_to_the_Sun')" target="_blank">{{'uou-node.slide-1-heliophysics' | translate}}</a></h4>
                        </div>
                        <div class="item item-video">
                            <h4><a (click)="qrcodeService.setUrl('https://www.esa.int/ESA_Multimedia/Videos/2018/11/What_is_space_weather#.YAmK9cnLZoA.link')" target="_blank">{{'uou-node.slide-1-space-weather' | translate}}</a></h4>
                        </div>
                        <div class="item item-webpage">
                            <h4><a (click)="qrcodeService.setUrl('http://www.esa.int/Science_Exploration/Space_Science/Cluster/Cluster_s_20_years_of_studying_Earth_s_magnetosphere')" target="_blank">{{'uou-node.slide-1-cluster' | translate}}</a></h4>
                        </div>
                        <div class="item item-webpage">
                            <h4><a (click)="qrcodeService.setUrl('https://sci.esa.int/web/cluster/2569-solar-wind')" target="_blank">{{'uou-node.slide-1-solar-wind' | translate}}</a></h4>
                        </div>
                    </section>
                    <section class="extra">
                        <div class="extra-trigger trusty-azure-extra-trigger" (click)="navigateToOtherNodes('ssa', 1)">
                            <div class="extra-label trusty-azure-label">{{'uou-node.safeguarding-space-activities' | translate}}</div>
                            <h6 class="extra-title trusty-azure-title">{{'uou-node.slide-1-space-weather' | translate}}</h6>
                        </div>
                    </section>
                </div>
            </main>
        </div>
        <!-- node -->
        <div class="swiper-slide excite-red-node">
            <main>
                <div class="main-wrapper" [ngClass]="isVideoSeen(2) ? 'hide-video' : 'show-video'">
                    <section class="heading">
                        <div class="heading-titles heading-titles-2">
                            <h3 (click)="navigateToInternalNode(0)">{{'uou-node.subtitle' | translate}}</h3>
                            <h1>{{'uou-node.slide-2-title' | translate}}</h1>
                        </div>
                    </section>
                    <section class="video">
                        <div class="video-close">
                            <img src="assets/icons/close-white.svg">
                        </div>
                        <div class="video-trigger" style="background-image: url(../../../assets/video/uou/p2.jpg)">
                            <img class="video-play" src="assets/images/single-nodes/commons/video-play-excite-red.svg">
                        </div>
                    </section>
                    <section class="items">
                        <div class="item item-find-out">
                            <h3>{{'uou-node.find-out-more' | translate}}</h3>
                        </div>
                        <div class="item item-reload">
                            <h4 class="video-trigger">{{'uou-node.replay-video' | translate}}</h4>
                        </div>
                        <div class="item item-webpage">
                            <h4><a (click)="qrcodeService.setUrl('http://www.esa.int/Science_Exploration/Space_Science/Rosetta')" target="_blank">{{'uou-node.slide-2-comets-and-rosetta' | translate}}</a></h4>
                        </div>
                        <div class="item item-video">
                            <h4><a (click)="qrcodeService.setUrl('https://www.esa.int/ESA_Multimedia/Videos/2019/01/Juice_s_Jovian_odyssey#.YAqwCXe2V8g.link')" target="_blank">{{'uou-node.slide-2-juice' | translate}}</a></h4>
                        </div>
                        <div class="item item-webpage">
                            <h4><a (click)="qrcodeService.setUrl('http://www.esa.int/Science_Exploration/Space_Science/BepiColombo')" target="_blank">{{'uou-node.slide-2-bepicolombo' | translate}}</a></h4>
                        </div>
                        <div class="item item-webpage">
                            <h4><a (click)="qrcodeService.setUrl('http://www.esa.int/Science_Exploration/Space_Science/Mars_Express_overview')" target="_blank">{{'uou-node.slide-2-mars-express' | translate}}</a></h4>
                        </div>
                        <div class="item item-webpage">
                            <h4><a (click)="qrcodeService.setUrl('http://www.esa.int/Science_Exploration/Human_and_Robotic_Exploration/Exploration/ExoMars')" target="_blank">{{'uou-node.slide-2-exomars' | translate}}</a></h4>
                        </div>
                        <div class="item item-webpage">
                            <h4><a (click)="qrcodeService.setUrl('http://www.esa.int/Science_Exploration/Space_Science/Venus_Express')" target="_blank">{{'uou-node.slide-2-venus-express' | translate}}</a></h4>
                        </div>
                        <div class="item item-webpage">
                            <h4><a (click)="qrcodeService.setUrl('https://psa.esa.int')" target="_blank">{{'uou-node.slide-2-planetary-science-archive' | translate}}</a></h4>
                        </div>
                    </section>
                    <section class="extra"></section>
                </div>
            </main>
        </div>
        <!-- node -->
        <div class="swiper-slide excite-red-node">
            <main>
                <div class="main-wrapper" [ngClass]="isVideoSeen(3) ? 'hide-video' : 'show-video'">
                    <section class="heading">
                        <div class="heading-titles heading-titles-3">
                            <h3 (click)="navigateToInternalNode(0)">{{'uou-node.subtitle' | translate}}</h3>
                            <h1>{{'uou-node.slide-3-title' | translate}}</h1>
                        </div>
                    </section>
                    <section class="video">
                        <div class="video-close">
                            <img src="assets/icons/close-white.svg">
                        </div>
                        <div class="video-trigger" style="background-image: url(../../../assets/video/uou/p3.jpg)">
                            <img class="video-play" src="assets/images/single-nodes/commons/video-play-excite-red.svg">
                        </div>
                    </section>
                    <section class="items">
                        <div class="item item-find-out">
                            <h3>{{'uou-node.find-out-more' | translate}}</h3>
                        </div>
                        <div class="item item-reload">
                            <h4 class="video-trigger">{{'uou-node.replay-video' | translate}}</h4>
                        </div>
                        <div class="item item-video">
                            <h4><a (click)="qrcodeService.setUrl('https://www.esa.int/ESA_Multimedia/Videos/2020/07/Meet_the_Experts_Other_worlds#.YAmR2wRiILE.link')" target="_blank">{{'uou-node.slide-3-meet-the-experts-other-worlds' | translate}}</a></h4>
                        </div>
                        <div class="item item-webpage">
                            <h4><a (click)="qrcodeService.setUrl('http://www.esa.int/Science_Exploration/Space_Science/Cheops')" target="_blank">{{'uou-node.slide-3-cheops' | translate}}</a></h4>
                        </div>
                        <div class="item item-webpage">
                            <h4><a (click)="qrcodeService.setUrl('https://sci.esa.int/web/ariel/')" target="_blank">{{'uou-node.slide-3-ariel' | translate}}</a></h4>
                        </div>
                        <div class="item item-webpage">
                            <h4><a (click)="qrcodeService.setUrl('https://sci.esa.int/web/plato/')" target="_blank">{{'uou-node.slide-3-plato' | translate}}</a></h4>
                        </div>
                        <div class="item item-webpage">
                            <h4><a (click)="qrcodeService.setUrl('http://www.esa.int/Science_Exploration/Space_Science/JWST')" target="_blank">{{'uou-node.slide-3-jwst' | translate}}</a></h4>
                        </div>
                    </section>
                    <section class="extra"></section>
                </div>
            </main>
        </div>
        <!-- node -->
        <div class="swiper-slide excite-red-node">
            <main>
                <div class="main-wrapper" [ngClass]="isVideoSeen(4) ? 'hide-video' : 'show-video'">
                    <section class="heading">
                        <div class="heading-titles heading-titles-4">
                            <h3 (click)="navigateToInternalNode(0)">{{'uou-node.subtitle' | translate}}</h3>
                            <h1>{{'uou-node.slide-4-title' | translate}}</h1>
                        </div>
                    </section>
                    <section class="video">
                        <div class="video-close">
                            <img src="assets/icons/close-white.svg">
                        </div>
                        <div class="video-trigger" style="background-image: url(../../../assets/video/uou/p4.jpg)">
                            <img class="video-play" src="assets/images/single-nodes/commons/video-play-excite-red.svg">
                        </div>
                    </section>
                    <section class="items">
                        <div class="item item-find-out">
                            <h3>{{'uou-node.find-out-more' | translate}}</h3>
                        </div>
                        <div class="item item-reload">
                            <h4 class="video-trigger">{{'uou-node.replay-video' | translate}}</h4>
                        </div>
                        <div class="item item-webpage">
                            <h4><a (click)="qrcodeService.setUrl('https://sci.esa.int/web/lisa')" target="_blank">{{'uou-node.slide-4-lisa' | translate}}</a></h4>
                        </div>
                        <div class="item item-webpage">
                            <h4><a (click)="qrcodeService.setUrl('http://www.esa.int/Science_Exploration/Space_Science/JWST')" target="_blank">{{'uou-node.slide-4-jwst' | translate}}</a></h4>
                        </div>
                        <div class="item item-webpage">
                            <h4><a (click)="qrcodeService.setUrl('https://sci.esa.int/web/athena')" target="_blank">{{'uou-node.slide-4-athena' | translate}}</a></h4>
                        </div>
                        <div class="item item-webpage">
                            <h4><a (click)="qrcodeService.setUrl('http://www.esa.int/Science_Exploration/Space_Science/XMM-Newton_overview')" target="_blank">{{'uou-node.slide-4-xmm' | translate}}</a></h4>
                        </div>
                        <div class="item item-webpage">
                            <h4><a (click)="qrcodeService.setUrl('https://sci.esa.int/web/integral')" target="_blank">{{'uou-node.slide-4-integral' | translate}}</a></h4>
                        </div>
                        <div class="item item-webpage">
                            <h4><a (click)="qrcodeService.setUrl('http://www.esa.int/Science_Exploration/Space_Science/Euclid_overview')" target="_blank">{{'uou-node.slide-4-euclid' | translate}}</a></h4>
                        </div>
                        <div class="item item-video">
                            <h4><a (click)="qrcodeService.setUrl('https://www.esa.int/ESA_Multimedia/Videos/2020/04/Hubble_30_years_unveiling_the_universe#.YAmTZaIb2B8.link')" target="_blank">{{'uou-node.slide-4-hubble' | translate}}</a></h4>
                        </div>
                        <div class="item item-webpage">
                            <h4><a (click)="qrcodeService.setUrl('http://www.esa.int/Science_Exploration/Space_Science/Planck_overview')" target="_blank">{{'uou-node.slide-4-planck' | translate}}</a></h4>
                        </div>
                        <div class="item item-webpage">
                            <h4><a (click)="qrcodeService.setUrl('https://cesar.esa.int/')" target="_blank">{{'uou-node.slide-4-cesar' | translate}}</a></h4>
                        </div>
                        <div class="item item-webpage">
                            <h4><a (click)="qrcodeService.setUrl('https://www.esa.int/Science_Exploration/Space_Science/Gaia_overview')" target="_blank">{{'uou-node.slide-4-gaia' | translate}}</a>
                            </h4>
                        </div>

                        <div class="item item-webpage">
                            <h4><a (click)="qrcodeService.setUrl('https://www.esa.int/Science_Exploration/Space_Science/Herschel_overview')" target="_blank">{{'uou-node.slide-4-herschel' | translate}}</a></h4>
                        </div>
                    </section>
                    <section class="extra"></section>
                </div>
            </main>
        </div>
        <!-- node -->
        <div class="swiper-slide excite-red-node mixed-with-enlight-yellow">
            <main>
                <div class="main-wrapper" [ngClass]="isVideoSeen(5) ? 'hide-video' : 'show-video'">
                    <section class="heading">
                        <div class="heading-titles heading-titles-5">
                            <h3 (click)="navigateToInternalNode(0)">{{'uou-node.subtitle' | translate}}</h3>
                            <h1>{{'uou-node.slide-5-title' | translate}}</h1>
                        </div>
                    </section>
                    <section class="video">
                        <div class="video-close">
                            <img src="assets/icons/close-white.svg">
                        </div>
                        <div class="video-trigger" style="background-image: url(../../../assets/video/uou/p5.jpg)">
                            <img class="video-play" src="assets/images/single-nodes/commons/video-play-excite-red.svg">
                        </div>
                    </section>
                    <section class="items">
                        <div class="item item-find-out">
                            <h3>{{'uou-node.find-out-more' | translate}}</h3>
                        </div>
                        <div class="item item-reload">
                            <h4 class="video-trigger">{{'uou-node.replay-video' | translate}}</h4>
                        </div>
                        <div class="item item-webpage">
                            <h4><a (click)="qrcodeService.setUrl('https://www.cosmos.esa.int/web/esdc')" target="_blank">{{'uou-node.slide-5-esac-data-centre' | translate}}</a></h4>
                        </div>
                        <div class="item item-webpage">
                            <h4><a (click)="qrcodeService.setUrl('https://sky.esa.int')" target="_blank">{{'uou-node.slide-5-esasky' | translate}}</a></h4>
                        </div>
                    </section>
                    <section class="extra">
                        <div class="extra-trigger vt-extra-trigger" [routerLink]="['/vt/esoc' | localize]">
                            <div class="extra-label vt-extra-label">{{'uou-node.virtual-tour' | translate}}</div>
                            <h6 class="extra-title vt-extra-title">ESA ESOC</h6>
                        </div>
                        <div class="extra-trigger vt-extra-trigger" [routerLink]="['/vt/esac' | localize]">
                            <div class="extra-label vt-extra-label">{{'uou-node.virtual-tour' | translate}}</div>
                            <h6 class="extra-title vt-extra-title">ESA ESAC</h6>
                        </div>
                        <div class="extra-trigger enlight-yellow-extra-trigger" (click)="navigateToOtherNodes('bbt', 0)">
                            <div class="extra-label enlight-yellow-label">{{'uou-node.building-the-best-tools' | translate}}</div>
                            <h6 class="extra-title enlight-yellow-title">{{'uou-node.go-to-this-journey' | translate}}</h6>
                        </div>
                    </section>
                </div>
            </main>
        </div>
        <!-- node -->
    </div>
    <div class="swiper-button-prev">
        <img src="assets/icons/left-white.svg">
    </div>
    <div class="swiper-button-next">
        <img src="assets/icons/right-white.svg">
    </div>
</div>