import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
//import { type } from 'os';
import { environment } from 'src/environments/environment';
import { fadeInAnimation } from 'src/route.animations';
import { HomeComponent } from './components/home/home.component';
import { PolicyPageComponent } from './components/policy-page/policy-page.component';
import { Constants } from './constants';
import { PolicyService } from './services/policy.service';
import { SharedService } from './services/shared.service';
import { QrCodeService } from './services/qr-code.service';

declare const matomo: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    fadeInAnimation
  ]
})
export class AppComponent implements OnInit {
  title = 'discoveresa';
  policyValidated: boolean = false;
  type: string = null;
  private policySubscription : Subscription;
  param = {value: 'ReMedia'};

  constructor(private sharedService: SharedService, private router: Router, private policyService: PolicyService,
    private qrCodeService: QrCodeService) {    
    this.router.events.subscribe((event) => {
      event instanceof NavigationEnd? 
        this.policySubscription = this.policyService.policyMsg.subscribe( result =>  this.policyValidated = this.policyService.validatePolicy("AppComponent")) : null;
        // console.log("Validate result: " +this.policyService.validatePolicy("AppComponent"))
      if (event instanceof NavigationEnd && this.policyValidated) {
        console.log("matomo is recording");
        matomo(window.location.href, environment.matomo.u, environment.matomo.setSiteId);
      }
    });
  }

  ngOnInit(): void {
    // setTimeout(
    //   () => this.qrCodeService.setUrl("www.google.com"), 7000
    // )
    console.log("App On Init");
    this.sharedService.subject.subscribe(event => {
      if (event && event.key === Constants.EVENT_ON_ROUTER_OUTLET) {
        if (event.data instanceof HomeComponent) {
          document.querySelector("html").style.overflowY = "hidden";
        } else {
          document.querySelector("html").removeAttribute("style");
        }
        if (event.data instanceof PolicyPageComponent) {
          this.type = "policy";
        }
      }
    });
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }

  ngOnDestroy(){
    try {
      this.policySubscription? this.policySubscription.unsubscribe() : null;
    } catch (error) {
      console.log(error);
    }
  }
}