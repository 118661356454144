<div class="swiper-container swiper-container-{{prefix}}">
  <div class="swiper-wrapper">
    <div class="swiper-slide excite-red-node">
      <main>
        <div class="main-wrapper">
          <section class="items">
            <div class="item item-sem first-node-video-trigger" [ngClass]="isVideoSeen(0) ? 'item-sem-replay' : 'item-sem-play'">
              <h4><span class="sequence-number">0</span>{{'sem-node.subtitle' | translate}}</h4>
            </div>
            <div class="item item-node-1" (click)="navigateToInternalNode(1)">
              <h4><span class="sequence-number">1</span>{{'sem-node.slide-1-title' | translate}}</h4>
            </div>
            <div class="item item-node-2" (click)="navigateToInternalNode(2)">
              <h4><span class="sequence-number">2</span>{{'sem-node.slide-2-title' | translate}}</h4>
            </div>
            <div class="item item-node-3" (click)="navigateToInternalNode(3)">
              <h4><span class="sequence-number">3</span>{{'sem-node.slide-3-title' | translate}}</h4>
            </div>
            <div class="item item-node-4" (click)="navigateToInternalNode(4)">
              <h4><span class="sequence-number">4</span>{{'sem-node.slide-4-title' | translate}}</h4>
            </div>
            <div class="item item-node-5" (click)="navigateToInternalNode(5)">
              <h4><span class="sequence-number">5</span>{{'sem-node.slide-5-title' | translate}}</h4>
            </div>
            <div class="item item-node-6" (click)="navigateToInternalNode(6)">
              <h4><span class="sequence-number">6</span>{{'sem-node.slide-6-title' | translate}}</h4>
            </div>
          </section>
        </div>
      </main>
    </div>
    <!-- node -->
    <div class="swiper-slide excite-red-node">
      <main>
        <div class="main-wrapper" [ngClass]="isVideoSeen(1) ? 'hide-video' : 'show-video'">
          <section class="heading">
            <div class="heading-titles heading-titles-1">
              <h3 (click)="navigateToInternalNode(0)">{{'sem-node.subtitle' | translate}}</h3>
              <h1>{{'sem-node.slide-1-title' | translate}}</h1>
            </div>
          </section>
          <section class="video">
            <div class="video-close">
              <img src="assets/icons/close-white.svg">
            </div>
            <div class="video-trigger" style="background-image: url(../../../assets/video/sem/p1.jpg)">
              <img class="video-play" src="assets/images/single-nodes/commons/video-play-excite-red.svg">
            </div>
          </section>
          <section class="items">
            <div class="item item-find-out">
              <h3>{{'sem-node.find-out-more' | translate}}</h3>
            </div>
            <div class="item item-reload">
              <h4 class="video-trigger">{{'sem-node.replay-video' | translate}}</h4>
            </div>
            <div class="item item-webpage">
              <h4><a (click)="qrcodeService.setUrl('https://www.esa.int/About_Us/EAC/Luna_facility_brings_Moon_to_Earth')" target="_blank">{{'sem-node.slide-1-luna' | translate}}</a></h4>
              <!--<p>Sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volulpat
              </p>-->
            </div>
            <!--div class="item item-webpage">
              <h4><a (click)="qrcodeService.setUrl('https://www.esa.int/YourWayToSpace')" target="_blank">Astronaut recruitment</a></h4>
              <p>Sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat vol</p>
            </div-->
            <div class="item item-video">
              <h4><a (click)="qrcodeService.setUrl('https://www.esa.int/ESA_Multimedia/Videos/2015/05/Centrifuge_8G')" target="_blank">{{'sem-node.slide-1-centrifuge' | translate}}</a></h4>
            </div>
            <div class="item item-webpage">
              <h4><a (click)="qrcodeService.setUrl('https://www.esa.int/About_Us/Careers_at_ESA/ESA_Astronaut_Selection')" target="_blank">{{'sem-node.slide-1-esa-astronaut-selection' | translate}}</a></h4>
            </div>
            <div class="item item-video">
              <h6>{{'sem-node.meet-the-experts' | translate}}</h6>
              <h4><a (click)="qrcodeService.setUrl('https://www.esa.int/ESA_Multimedia/Videos/2020/06/Meet_the_Experts_Astronaut_training#.YAqulUV5f60.link')" target="_blank">{{'sem-node.slide-1-astronaut-training' | translate}}</a></h4>
            </div>
          </section>
          <section class="extra">
            <div class="extra-trigger vt-extra-trigger" [routerLink]="['/vt/eac' | localize]">
              <div class="extra-label vt-extra-label">{{'sem-node.virtual-tour' | translate}}</div>
              <h6 class="extra-title vt-extra-title">ESA EAC</h6>
            </div>
            <div class="extra-trigger is-extra-trigger" [routerLink]="['/is/helmet' | localize]">
              <div class="extra-label is-extra-label">{{'sem-node.join-in' | translate}}</div>
              <h6 class="extra-title is-extra-title">{{'sem-node.helmet-text' | translate}}</h6>
            </div>
            <!--<div class="extra-trigger excite-red-extra-trigger">
                            <div class="extra-label excite-red-label">Related node</div>
                            <h6 class="extra-title excite-red-title">Node Title</h6>
                        </div>
                        <div class="extra-trigger trusty-azure-extra-trigger">
                            <div class="extra-label trusty-azure-label">Related node</div>
                            <h6 class="extra-title trusty-azure-title">Node Title</h6>
                        </div>
                        <div class="extra-trigger enlight-yellow-extra-trigger">
                            <div class="extra-label enlight-yellow-label">Related node</div>
                            <h6 class="extra-title enlight-yellow-title">Node Title</h6>
                        </div>
                        <div class="extra-trigger pure-teal-extra-trigger">
                            <div class="extra-label pure-teal-label">Related node</div>
                            <h6 class="extra-title pure-teal-title">Node Title</h6>
                        </div>-->
          </section>
        </div>
      </main>
    </div>
    <!-- node -->
    <div class="swiper-slide excite-red-node">
      <main>
        <div class="main-wrapper" [ngClass]="isVideoSeen(2) ? 'hide-video' : 'show-video'">
          <section class="heading">
            <div class="heading-titles heading-titles-2">
              <h3 (click)="navigateToInternalNode(0)">{{'sem-node.subtitle' | translate}}</h3>
              <h1>{{'sem-node.slide-2-title' | translate}}</h1>
            </div>
          </section>
          <section class="video">
            <div class="video-close">
              <img src="assets/icons/close-white.svg">
            </div>
            <div class="video-trigger" style="background-image: url(../../../assets/video/sem/p2.jpg)">
              <img class="video-play" src="assets/images/single-nodes/commons/video-play-excite-red.svg">
            </div>
          </section>
          <section class="items">
            <div class="item item-find-out">
              <h3>{{'sem-node.find-out-more' | translate}}</h3>
            </div>
            <div class="item item-reload">
              <h4 class="video-trigger">{{'sem-node.replay-video' | translate}}</h4>
            </div>
            <div class="item item-video">
              <h4><a (click)="qrcodeService.setUrl('https://www.esa.int/ESA_Multimedia/Videos/2019/11/CAVES_2019#.YAqvaxrm3Lg.link')" target="_blank">{{'sem-node.slide-2-caves-traning' | translate}}</a></h4>
            </div>
            <div class="item item-video">
              <h4><a (click)="qrcodeService.setUrl('https://www.esa.int/ESA_Multimedia/Videos/2019/08/Pangaea_2019_dry_run#.YAqvsAFP1Sg.link')" target="_blank">{{'sem-node.slide-2-pangaea' | translate}}</a></h4>
            </div>
            <div class="item item-video">
              <h4><a (click)="qrcodeService.setUrl('https://www.esa.int/ESA_Multimedia/Videos/2019/11/Rock_sampling_from_space_Analog-1#.YAqwIDzWjbw.link')" target="_blank">{{'sem-node.slide-2-analog-1' | translate}}</a></h4>
            </div>
            <div class="item item-video">
              <h6>{{'sem-node.meet-the-experts' | translate}}</h6>
              <h4><a (click)="qrcodeService.setUrl('https://www.esa.int/ESA_Multimedia/Videos/2020/05/Meet_the_Experts_Isolation_in_Antarctica#.YAqwXzmAZCI.link')" target="_blank">{{'sem-node.slide-2-isolation-in-antartica' | translate}}</a></h4>
            </div>
            <div class="item item-video">
              <h4><a (click)="qrcodeService.setUrl('https://www.esa.int/ESA_Multimedia/Videos/2017/12/Zero-G_science#.YAqw4n09MAQ.link')" target="_blank">{{'sem-node.slide-2-parabolic-flight' | translate}}</a></h4>
            </div>
            <div class="item item-webpage">
              <h6>{{'sem-node.get-involved' | translate}}</h6>
              <h4><a (click)="qrcodeService.setUrl('https://www.esa.int/Education/Fly_Your_Thesis')" target="_blank">{{'sem-node.fly-your-thesis' | translate}}</a></h4>
            </div>
          </section>
          <section class="extra">
            <!--<div class="extra-trigger vt-extra-trigger">
                            <div class="extra-label vt-extra-label">Virtual tour</div>
                            <h6 class="extra-title vt-extra-title">ESA ECSAT</h6>
                        </div>
                        <div class="extra-trigger is-extra-trigger">
                            <div class="extra-label is-extra-label">Join in</div>
                            <h6 class="extra-title is-extra-title">Content title</h6>
                        </div>
                        <div class="extra-trigger excite-red-extra-trigger">
                            <div class="extra-label excite-red-label">Related node</div>
                            <h6 class="extra-title excite-red-title">Node Title</h6>
                        </div>
                        <div class="extra-trigger trusty-azure-extra-trigger">
                            <div class="extra-label trusty-azure-label">Related node</div>
                            <h6 class="extra-title trusty-azure-title">Node Title</h6>
                        </div>
                        <div class="extra-trigger enlight-yellow-extra-trigger">
                            <div class="extra-label enlight-yellow-label">Related node</div>
                            <h6 class="extra-title enlight-yellow-title">Node Title</h6>
                        </div>
                        <div class="extra-trigger pure-teal-extra-trigger">
                            <div class="extra-label pure-teal-label">Related node</div>
                            <h6 class="extra-title pure-teal-title">Node Title</h6>
                        </div>-->
          </section>
        </div>
      </main>
    </div>
    <!-- node -->
    <div class="swiper-slide excite-red-node">
      <main>
        <div class="main-wrapper" [ngClass]="isVideoSeen(3) ? 'hide-video' : 'show-video'">
          <section class="heading">
            <div class="heading-titles heading-titles-3">
              <h3 (click)="navigateToInternalNode(0)">{{'sem-node.subtitle' | translate}}</h3>
              <h1>{{'sem-node.slide-3-title' | translate}}</h1>
            </div>
          </section>
          <section class="video">
            <div class="video-close">
              <img src="assets/icons/close-white.svg">
            </div>
            <div class="video-trigger" style="background-image: url(../../../assets/video/sem/p3.jpg)">
              <img class="video-play" src="assets/images/single-nodes/commons/video-play-excite-red.svg">
            </div>
          </section>
          <section class="items">
            <div class="item item-find-out">
              <h3>{{'sem-node.find-out-more' | translate}}</h3>
            </div>
            <div class="item item-reload">
              <h4 class="video-trigger">{{'sem-node.replay-video' | translate}}</h4>
            </div>
            <div class="item item-webpage">
              <h4><a (click)="qrcodeService.setUrl('https://www.esa.int/Science_Exploration/Human_and_Robotic_Exploration/International_Space_Station/Where_is_the_International_Space_Station')" target="_blank">{{'sem-node.slide-3-where-is-iss' | translate}}</a></h4>
            </div>
            <div class="item item-video">
              <h4><a (click)="qrcodeService.setUrl('https://www.esa.int/ESA_Multimedia/Videos/2016/05/Narrated_tour_of_the_International_Space_Station#.YAqxzaHSDTE.link')" target="_blank">{{'sem-node.slide-3-narrated-tour' | translate}}</a></h4>
            </div>
            <div class="item item-video">
              <h4><a (click)="qrcodeService.setUrl('https://youtube.com/playlist?list=PLbyvawxScNbvfN1Oe5Eipz0MxS_-V9z-g')" target="_blank">{{'sem-node.slide-3-astronaut-tours' | translate}}</a></h4>
            </div>
            <div class="item item-video">
              <h6>{{'sem-node.meet-the-experts' | translate}}</h6>
              <h4><a (click)="qrcodeService.setUrl('https://www.esa.int/ESA_Multimedia/Videos/2020/08/Meet_the_Experts_Laboratory_in_Space#.YAqyMzDZJfM.link')" target="_blank">{{'sem-node.slide-3-laboratory-in-space' | translate}}</a></h4>
            </div>
          </section>
          <section class="extra">
            <div class="extra-trigger vt-extra-trigger" [routerLink]="['/vt/eac' | localize]">
              <div class="extra-label vt-extra-label">{{'sem-node.virtual-tour' | translate}}</div>
              <h6 class="extra-title vt-extra-title">ESA EAC</h6>
            </div>
            <!--<div class="extra-trigger is-extra-trigger">
              <div class="extra-label is-extra-label">Join in</div>
              <h6 class="extra-title is-extra-title">Content title</h6>
            </div>
            <div class="extra-trigger excite-red-extra-trigger">
              <div class="extra-label excite-red-label">Related node</div>
              <h6 class="extra-title excite-red-title">Node Title</h6>
            </div>
            <div class="extra-trigger trusty-azure-extra-trigger">
              <div class="extra-label trusty-azure-label">Related node</div>
              <h6 class="extra-title trusty-azure-title">Node Title</h6>
            </div>
            <div class="extra-trigger enlight-yellow-extra-trigger">
              <div class="extra-label enlight-yellow-label">Related node</div>
              <h6 class="extra-title enlight-yellow-title">Node Title</h6>
            </div>
            <div class="extra-trigger pure-teal-extra-trigger">
              <div class="extra-label pure-teal-label">Related node</div>
              <h6 class="extra-title pure-teal-title">Node Title</h6>
            </div>-->
          </section>
        </div>
      </main>
    </div>
    <!-- node -->
    <div class="swiper-slide excite-red-node">
      <main>
        <div class="main-wrapper" [ngClass]="isVideoSeen(4) ? 'hide-video' : 'show-video'">
          <section class="heading">
            <div class="heading-titles heading-titles-4">
              <h3 (click)="navigateToInternalNode(0)">{{'sem-node.subtitle' | translate}}</h3>
              <h1>{{'sem-node.slide-4-title' | translate}}</h1>
            </div>
          </section>
          <section class="video">
            <div class="video-close">
              <img src="assets/icons/close-white.svg">
            </div>
            <div class="video-trigger" style="background-image: url(../../../assets/video/sem/p4.jpg)">
              <img class="video-play" src="assets/images/single-nodes/commons/video-play-excite-red.svg">
            </div>
          </section>
          <section class="items">
            <div class="item item-find-out">
              <h3>{{'sem-node.find-out-more' | translate}}</h3>
            </div>
            <div class="item item-reload">
              <h4 class="video-trigger">{{'sem-node.replay-video' | translate}}</h4>
            </div>
            <div class="item item-video">
              <h4><a (click)="qrcodeService.setUrl('https://www.esa.int/ESA_Multimedia/Videos/2020/01/Forward_to_the_Moon_with_ESA#.YAqzHK857fk.link')" target="_blank">{{'sem-node.slide-4-forward-to-the-moon' | translate}}</a></h4>
            </div>
            <div class="item item-webpage">
              <h4><a (click)="qrcodeService.setUrl('https://www.esa.int/Science_Exploration/Human_and_Robotic_Exploration/Exploration/Gateway')" target="_blank">{{'sem-node.slide-4-gateway' | translate}}</a></h4>
            </div>
            <div class="item item-image">
              <h4><a (click)="qrcodeService.setUrl('https://www.esa.int/ESA_Multimedia/Images/2020/10/What_is_Esprit#.YAqzcYKoBQQ.link')" target="_blank">{{'sem-node.slide-4-esprit' | translate}}</a></h4>
            </div>
            <div class="item item-image">
              <h4><a (click)="qrcodeService.setUrl('https://www.esa.int/ESA_Multimedia/Images/2021/01/Gateway_zoom_on_I-Hab#.YArIZEBxcPI.link')" target="_blank">{{'sem-node.slide-4-ihab' | translate}}</a></h4>
            </div>
            <div class="item item-video">
              <h4><a (click)="qrcodeService.setUrl('https://www.esa.int/ESA_Multimedia/Videos/2019/11/Paxi_on_the_ISS_EVA_suits#.YArKYw9yGuU.link')" target="_blank">{{'sem-node.slide-4-spacesuits' | translate}}</a></h4>
            </div>
          </section>
          <section class="extra">
            <div class="extra-trigger vt-extra-trigger" [routerLink]="['/vt/estec' | localize]">
              <div class="extra-label vt-extra-label">{{'sem-node.virtual-tour' | translate}}</div>
              <h6 class="extra-title vt-extra-title">ESA ESTEC</h6>
            </div>
          </section>
          <!--<div class="extra-trigger vt-extra-trigger">
                            <div class="extra-label vt-extra-label">Virtual tour</div>
                            <h6 class="extra-title vt-extra-title">ESA ECSAT</h6>
                        </div>
                        <div class="extra-trigger is-extra-trigger">
                            <div class="extra-label is-extra-label">Join in</div>
                            <h6 class="extra-title is-extra-title">Content title</h6>
                        </div>
                        <div class="extra-trigger excite-red-extra-trigger">
                            <div class="extra-label excite-red-label">Related node</div>
                            <h6 class="extra-title excite-red-title">Node Title</h6>
                        </div>
                        <div class="extra-trigger trusty-azure-extra-trigger">
                            <div class="extra-label trusty-azure-label">Related node</div>
                            <h6 class="extra-title trusty-azure-title">Node Title</h6>
                        </div>
                        <div class="extra-trigger enlight-yellow-extra-trigger">
                            <div class="extra-label enlight-yellow-label">Related node</div>
                            <h6 class="extra-title enlight-yellow-title">Node Title</h6>
                        </div>
                        <div class="extra-trigger pure-teal-extra-trigger">
                            <div class="extra-label pure-teal-label">Related node</div>
                            <h6 class="extra-title pure-teal-title">Node Title</h6>
                        </div>-->
        </div>
      </main>
    </div>
    <!-- node -->
    <div class="swiper-slide excite-red-node mixed-with-pure-teal mixed-with-trusty-azure">
      <main>
        <div class="main-wrapper" [ngClass]="isVideoSeen(5) ? 'hide-video' : 'show-video'">
          <section class="heading">
            <div class="heading-titles heading-titles-5">
              <h3 (click)="navigateToInternalNode(0)">{{'sem-node.subtitle' | translate}}</h3>
              <h1>{{'sem-node.slide-5-title' | translate}}</h1>
            </div>
          </section>
          <section class="video">
            <div class="video-close">
              <img src="assets/icons/close-white.svg">
            </div>
            <div class="video-trigger" style="background-image: url(../../../assets/video/sem/p5.jpg)">
              <img class="video-play" src="assets/images/single-nodes/commons/video-play-excite-red.svg">
            </div>
          </section>
          <section class="items">
            <div class="item item-find-out">
              <h3>{{'sem-node.find-out-more' | translate}}</h3>
            </div>
            <div class="item item-reload">
              <h4 class="video-trigger">{{'sem-node.replay-video' | translate}}</h4>
            </div>
            <div class="item item-video">
              <h6>{{'sem-node.meet-the-esa-experts' | translate}}</h6>
              <h4><a (click)="qrcodeService.setUrl('https://www.esa.int/ESA_Multimedia/Videos/2020/02/Meet_the_ESA_experts_3D_Printing_on_the_Moon#.YAq0EdDBFRE.link')" target="_blank">{{'sem-node.slide-5-3d-printing' | translate}}</a></h4>
            </div>
            <div class="item item-video">
              <h6>{{'sem-node.meet-the-esa-experts' | translate}}</h6>
              <h4><a (click)="qrcodeService.setUrl('https://www.esa.int/ESA_Multimedia/Videos/2020/02/Meet_the_ESA_experts_Future_Moon_exploration#.YAq0S_7wzhc.link')" target="_blank">{{'sem-node.slide-5-future-moon-exploration' | translate}}</a></h4>
            </div>
            <div class="item item-video">
              <h4><a (click)="qrcodeService.setUrl('https://www.esa.int/ESA_Multimedia/Videos/2015/01/Destination_Moon#.YArJyqSGwDY.link')" target="_blank">{{'sem-node.slide-5-lunar-science' | translate}}</a></h4>
            </div>
            <div class="item item-video">
              <h4><a (click)="qrcodeService.setUrl('https://www.esa.int/ESA_Multimedia/Videos/2019/01/Preparing_astronaut_lunar_exploration#.YArIzDZWy-U.link')" target="_blank">{{'sem-node.slide-5-preparing-astronaut' | translate}}</a></h4>
            </div>
            <div class="item item-video">
              <h4><a (click)="qrcodeService.setUrl('https://www.esa.int/ESA_Multimedia/Videos/2019/07/Prospecting_the_Moon#.YArJTkzb8gE.link')" target="_blank">{{'sem-node.slide-5-using-lunar-resources' | translate}}</a></h4>
            </div>
            <div class="item item-webpage">
              <h6>{{'sem-node.get-involved' | translate}}</h6>
              <h4><a (click)="qrcodeService.setUrl('https://www.esa.int/Education/Mission_X')" target="_blank">{{'sem-node.slide-5-train-like-an-astronaut' | translate}}</a></h4>
            </div>
            <div class="item item-video">
              <h4><a (click)="qrcodeService.setUrl('https://www.esa.int/ESA_Multimedia/Videos/2020/11/Moonlight_connecting_Earth_with_the_Moon')" target="_blank">{{'sem-node.slide-5-moonlight' | translate}}</a></h4>
            </div>
          </section>
          <section class="extra">
            <div class="extra-trigger vt-extra-trigger" [routerLink]="['/vt/ecsat' | localize]">
              <div class="extra-label vt-extra-label">{{'sem-node.virtual-tour' | translate}}</div>
              <h6 class="extra-title vt-extra-title">ESA ECSAT</h6>
            </div>
            <div class="extra-trigger vt-extra-trigger" [routerLink]="['/vt/estec' | localize]">
              <div class="extra-label vt-extra-label">{{'sem-node.virtual-tour' | translate}}</div>
              <h6 class="extra-title vt-extra-title">ESA ESTEC</h6>
            </div>
            <div class="extra-trigger pure-teal-extra-trigger" (click)="navigateToOtherNodes('ile', 2)">
              <div class="extra-label pure-teal-label">{{'sem-node.improving-life-on-earth' | translate}}</div>
              <h6 class="extra-title pure-teal-title">{{'sem-node.using-space-data' | translate}}</h6>
            </div>
            <div class="extra-trigger trusty-azure-extra-trigger" (click)="navigateToOtherNodes('ssa', 0)">
              <div class="extra-label trusty-azure-label">{{'sem-node.safeguarding-space-activities' | translate}}</div>
              <h6 class="extra-title trusty-azure-title">{{'sem-node.go-to-this-journey' | translate}}</h6>
            </div>
            <!--<div class="extra-trigger vt-extra-trigger">
                            <div class="extra-label vt-extra-label">Virtual tour</div>
                            <h6 class="extra-title vt-extra-title">ESA ECSAT</h6>
                        </div>
                        <div class="extra-trigger is-extra-trigger">
                            <div class="extra-label is-extra-label">Join in</div>
                            <h6 class="extra-title is-extra-title">Content title</h6>
                        </div>
                        <div class="extra-trigger excite-red-extra-trigger">
                            <div class="extra-label excite-red-label">Related node</div>
                            <h6 class="extra-title excite-red-title">Node Title</h6>
                        </div>
                        <div class="extra-trigger trusty-azure-extra-trigger">
                            <div class="extra-label trusty-azure-label">Related node</div>
                            <h6 class="extra-title trusty-azure-title">Node Title</h6>
                        </div>
                        <div class="extra-trigger enlight-yellow-extra-trigger">
                            <div class="extra-label enlight-yellow-label">Related node</div>
                            <h6 class="extra-title enlight-yellow-title">Node Title</h6>
                        </div>
                        <div class="extra-trigger pure-teal-extra-trigger">
                            <div class="extra-label pure-teal-label">Related node</div>
                            <h6 class="extra-title pure-teal-title">Node Title</h6>
                        </div>-->
          </section>
        </div>
      </main>
    </div>
    <!-- node -->
    <div class="swiper-slide excite-red-node">
      <main>
        <div class="main-wrapper" [ngClass]="isVideoSeen(6) ? 'hide-video' : 'show-video'">
          <section class="heading">
            <div class="heading-titles heading-titles-6">
              <h3 (click)="navigateToInternalNode(0)">{{'sem-node.subtitle' | translate}}</h3>
              <h1>{{'sem-node.slide-6-title' | translate}}</h1>
            </div>
          </section>
          <section class="video">
            <div class="video-close">
              <img src="assets/icons/close-white.svg">
            </div>
            <div class="video-trigger" style="background-image: url(../../../assets/video/sem/p6.jpg)">
              <img class="video-play" src="assets/images/single-nodes/commons/video-play-excite-red.svg">
            </div>
          </section>
          <section class="items">
            <div class="item item-find-out">
              <h3>{{'sem-node.find-out-more' | translate}}</h3>
            </div>
            <div class="item item-reload">
              <h4 class="video-trigger">{{'sem-node.replay-video' | translate}}</h4>
            </div>
            <div class="item item-webpage">
              <h4><a (click)="qrcodeService.setUrl('http://www.esa.int/Science_Exploration/Human_and_Robotic_Exploration/Exploration/ExoMars')" target="_blank">{{'sem-node.slide-6-exomars' | translate}}</a></h4>
            </div>
            <div class="item item-webpage">
              <h4><a (click)="qrcodeService.setUrl('http://www.esa.int/Science_Exploration/Space_Science/Mars_Express_overview')" target="_blank">{{'sem-node.slide-6-marsexpress' | translate}}</a></h4>
            </div>
            <div class="item item-webpage">
              <h4><a (click)="qrcodeService.setUrl('http://www.esa.int/Science_Exploration/Human_and_Robotic_Exploration/Exploration/Mars_sample_return')" target="_blank">{{'sem-node.slide-6-mars-sample-return' | translate}}</a></h4>
            </div>
            <div class="item item-webpage">
              <h4><a (click)="qrcodeService.setUrl('https://sacf.esa.int/')" target="_blank">{{'sem-node.slide-6-sample-analogue-curation-facility' | translate}}</a></h4>
            </div>
          </section>
          <section class="extra">
            <div class="extra-trigger vt-extra-trigger" [routerLink]="['/vt/ecsat' | localize]">
              <div class="extra-label vt-extra-label">{{'sem-node.virtual-tour' | translate}}</div>
              <h6 class="extra-title vt-extra-title">ESA ECSAT</h6>
            </div>
            <div class="extra-trigger vt-extra-trigger" [routerLink]="['/vt/esac' | localize]">
              <div class="extra-label vt-extra-label">{{'sem-node.virtual-tour' | translate}}</div>
              <h6 class="extra-title vt-extra-title">ESA ESAC</h6>
            </div>
            <div class="extra-trigger vt-extra-trigger" [routerLink]="['/vt/esoc' | localize]">
              <div class="extra-label vt-extra-label">{{'sem-node.virtual-tour' | translate}}</div>
              <h6 class="extra-title vt-extra-title">ESA ESOC</h6>
            </div>
            <div class="extra-trigger vt-extra-trigger" [routerLink]="['/vt/estec' | localize]">
              <div class="extra-label vt-extra-label">{{'sem-node.virtual-tour' | translate}}</div>
              <h6 class="extra-title vt-extra-title">ESA ESTEC</h6>
            </div>
            <div class="extra-trigger excite-red-extra-trigger" (click)="navigateToOtherNodes('uou', 2)">
              <div class="extra-label excite-red-label">{{'sem-node.understanding-the-universe' | translate}}</div>
              <h6 class="extra-title excite-red-title">{{'sem-node.the-solar-system' | translate}}</h6>
            </div>
            <!--<div class="extra-trigger vt-extra-trigger">
                            <div class="extra-label vt-extra-label">Virtual tour</div>
                            <h6 class="extra-title vt-extra-title">ESA ECSAT</h6>
                        </div>
                        <div class="extra-trigger is-extra-trigger">
                            <div class="extra-label is-extra-label">Join in</div>
                            <h6 class="extra-title is-extra-title">Content title</h6>
                        </div>
                        <div class="extra-trigger excite-red-extra-trigger">
                            <div class="extra-label excite-red-label">Related node</div>
                            <h6 class="extra-title excite-red-title">Node Title</h6>
                        </div>
                        <div class="extra-trigger trusty-azure-extra-trigger">
                            <div class="extra-label trusty-azure-label">Related node</div>
                            <h6 class="extra-title trusty-azure-title">Node Title</h6>
                        </div>
                        <div class="extra-trigger enlight-yellow-extra-trigger">
                            <div class="extra-label enlight-yellow-label">Related node</div>
                            <h6 class="extra-title enlight-yellow-title">Node Title</h6>
                        </div>
                        <div class="extra-trigger pure-teal-extra-trigger">
                            <div class="extra-label pure-teal-label">Related node</div>
                            <h6 class="extra-title pure-teal-title">Node Title</h6>
                        </div>-->
          </section>
        </div>
      </main>
    </div>
    <!-- node -->
  </div>
  <div class="swiper-button-prev">
    <img src="assets/icons/left-white.svg">
  </div>
  <div class="swiper-button-next">
    <img src="assets/icons/right-white.svg">
  </div>
</div>
