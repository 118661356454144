import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { BbtNodeComponent } from '../bbt-node/bbt-node.component';
import { IleNodeComponent } from '../ile-node/ile-node.component';
import { PoeNodeComponent } from '../poe-node/poe-node.component';
import { SemNodeComponent } from '../sem-node/sem-node.component';
import { SsaNodeComponent } from '../ssa-node/ssa-node.component';
import { UouNodeComponent } from '../uou-node/uou-node.component';

@Component({
  selector: 'app-node-factory',
  template: `
    <ng-container *ngComponentOutlet="content"></ng-container>
  `
})
export class NodeFactoryComponent {

  content;

  constructor(private route: ActivatedRoute) {
    this.route.params.subscribe((params: Params) => {
      switch (params.prefix) {
        case 'sem':
          this.content = SemNodeComponent;
          break;
        case 'poe':
          this.content = PoeNodeComponent;
          break;
        case 'ssa':
          this.content = SsaNodeComponent;
          break;
        case 'uou':
          this.content = UouNodeComponent;
          break;
        case 'ile':
          this.content = IleNodeComponent;
          break;
        case 'bbt':
          this.content = BbtNodeComponent;
          break;
        default:
      }

    });
  }

}
