import { Component, ElementRef, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { EventEmitter } from 'protractor';

@Component({
  selector: 'app-vt-ui',
  templateUrl: './vt-ui.component.html',
  styleUrls: ['./vt-ui.component.css']
})
export class VtUiComponent implements OnInit {
  // @ViewChildren(".particle") private particles: QueryList<ElementRef>;
  onGoing: boolean = false;
  onClosing: boolean = false;
  counter: number[] = [];
  constructor(private routerService : Router) { }


  ngOnInit(): void {
    this.particleInit();
  }

  // ngAfterViewInit(): void {
  //   this.particles.changes.subscribe((particle) => {
  //     console.log(particle)
  //   })
  // }

  openVt() {
    console.log("VT Button Triggered!")
    this.onGoing = true;
    this.onClosing = false;

  }

  closeVt() {
    console.log("VT Button Triggered!")

    const closingStarted = new Promise((resolve, reject) => {
      resolve(this.onClosing = true);
    });
    const hidingVt = new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.onGoing = false);
      }, 1200);
    })
    const hidingClosedVt = new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.onClosing = false);
      }, 1500);
    })
  }

  particleInit(): void {
    for (let i = 1; i <= 800; i++) {
      this.counter.push(i);
    }
    /*let particles = document.querySelectorAll<HTMLElement>('.particle');
    particles.forEach(particle => {
      let vh = Math.floor(Math.random() * 100);
      let vw = Math.floor(Math.random() * 100);
      particle.style.transform = "translate(" + vw + "vw," + vh + "vh)";
      particle.style.background = "white";
      particle.style.animationDuration = "0.5s";
      particle.style.animationDelay = Math.random() / 2 + 's';
      // transform: translate(71vw, 92vh);
      // background: white;
      // animation-duration: 0.53s;
      // animation-delay: -0.1643s;
    })*/

  }
}




