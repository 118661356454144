<!-- <div id="toolbar">
    <button (click)="zoomNode(6)" id="test">test zoom</button>
    <button (click)="reset()" id="test">reset</button>
</div> -->
<svg #home class="map-group">
  <g id="main-group">
    <image id="background" style="opacity: 1;" [attr.xlink:href]="'./assets/i18n/'  + currentLang +  '/images/background.jpg'"></image>
  </g>
</svg>
<app-vt-ui></app-vt-ui>
