import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { BehaviorSubject } from 'rxjs';
import { Constants } from '../constants';
import { SharedMessage } from '../model/shared-message';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  nodes = {
    "sem": [0, 0, 0, 0, 0, 0],
    "uou": [0, 0, 0, 0, 0, 0],
    "ssa": [0, 0, 0, 0, 0],
    "poe": [0, 0, 0, 0, 0],
    "bbt": [0, 0, 0, 0, 0],
    "ile": [0, 0, 0, 0, 0]
  }

  videos = {
    "sem": [0, 0, 0, 0, 0, 0],
    "uou": [0, 0, 0, 0, 0, 0],
    "ssa": [0, 0, 0, 0, 0],
    "poe": [0, 0, 0, 0, 0],
    "bbt": [0, 0, 0, 0, 0],
    "ile": [0, 0, 0, 0, 0]
  }

  subject = new BehaviorSubject<SharedMessage>({});

  lastIndex: number = -1;
  lastPrefix: string;
  firstLoad: boolean = true;
  menuOpen: boolean = false;
  homeVideo: boolean = false;

  constructor(private router: Router, private localize: LocalizeRouterService) { }

  markNodeAsSeen(prefix: string, index: number) {
    this.lastPrefix = prefix;
    this.nodes[prefix][index] = 1;
  }

  markVideoAsSeen(prefix: string, index: number) {
    this.lastPrefix = prefix;
    this.videos[prefix][index] = 1;
  }

  isNodeSeen(path: string, index: number) {
    return this.nodes[path][index] == 1;
  }

  isVideoSeen(path: string, index: number) {
    return this.videos[path][index] == 1;
  }

  showLoader() {
    this.subject.next({
      key: Constants.EVENT_ON_SHOW_LOADER
    })
  }

  hideLoader() {
    this.subject.next({
      key: Constants.EVENT_ON_HIDE_LOADER
    })
  }

  fadeOutAndNavigateTo(route: Array<any>, ms: number = 500) {
    let translatedPath: any = this.localize.translateRoute(route);
    // console.log("Original route: " + route);
    // console.log("Translated route: " + translatedPath);
    let bodyElement: HTMLBodyElement = document.querySelector('body');
    bodyElement.classList.remove("fade-in");
    bodyElement.classList.add("fade-out");
    setTimeout(() => {
      this.router.navigate(translatedPath);
    }, ms);
  }
}
