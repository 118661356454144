<div appNoRightClick>
    <app-loader></app-loader>
    <!-- <app-matomo></app-matomo>
    -->
    <app-qr-code></app-qr-code>
    <app-header></app-header>
    <div>
        <!-- asdf <div>{{ 'HELLO' | translate:param }}</div> -->
        <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
    <app-policy-bar *ngIf="type !== 'policy'"></app-policy-bar>
</div>