import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QrCodeService {
  public url: BehaviorSubject<String> = new BehaviorSubject<String>('');

  constructor() { }

  setUrl(newUrl: String) {
    this.url.next(newUrl);
  }

  resetUrl() {
    this.url.next('');
  }

  getUrl() {
    return this.url;
  }
}
