import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Constants } from 'src/app/constants';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements AfterViewInit {

  @ViewChild("progressBar") progressBar: ElementRef;
  @ViewChild("progressButton") progressButton: ElementRef;

  timeouts = [];
  ready = false;

  constructor(private sharedService: SharedService, private element: ElementRef) {
    this.sharedService.subject.subscribe(sharedMessage => {
      if (sharedMessage && sharedMessage.key === Constants.EVENT_ON_READY_LOADER) {
        //this.hide();
      }
      if (sharedMessage && sharedMessage.key === Constants.EVENT_ON_HIDE_LOADER) {
        this.ready = true;
        for (var i = 0; i < this.timeouts.length; i++) {
          clearTimeout(this.timeouts[i]);
        }
        this.progressBar.nativeElement.style.right = '0%';
        setTimeout(() => {
          this.progressButton.nativeElement.classList.add('active');
        }, 300)

        this.sharedService.firstLoad = false;
      }
      if (sharedMessage && sharedMessage.key === Constants.EVENT_ON_SHOW_LOADER) {
        if (this.sharedService.firstLoad) {
          this.show();
        }
      }
    });
  }

  ngAfterViewInit(): void {
    for (let i = 0; i < 100; i++) {
      let id = setTimeout((_self = this, x = i) => {
        _self.progressBar.nativeElement.style.right = (100 - x) + '%';
      }, 100 * i);
      this.timeouts.push(id);
    }
  }

  get bodyElement() {
    return document.getElementsByTagName("body")[0];
  }

  hide() {
    if (this.ready) {
      this.bodyElement.classList.add("fade-out-loader");
      setTimeout((_self = this) => {
        _self.element.nativeElement.style.display = "none";
        this.sharedService.subject.next({
          key: Constants.EVENT_ON_READY_LOADER
        });
      }, 500);
    }
  }

  show() {
    this.element.nativeElement.style.display = "flex";
  }
}
