import { AfterViewInit, Injectable, OnDestroy, OnInit } from '@angular/core';
import { PageComponent } from '../page/page.component';
import { Swiper, Navigation } from 'swiper';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SharedService } from 'src/app/services/shared.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
Swiper.use([Navigation]);

@Injectable()
export abstract class NodeComponent extends PageComponent implements OnInit, AfterViewInit {
  swiper;
  index;
  params;

  constructor(protected route: ActivatedRoute, protected router: Router, protected sharedService: SharedService,
    protected localize: LocalizeRouterService) {
    super();
    this.route.params.subscribe((params: Params) => {
      this.params = params;
      this.index = params.index;
    });
  }

  abstract get prefix(): string;

  isVideoSeen(index): boolean {
    return this.sharedService.isVideoSeen(this.prefix, index);
  }

  ngOnInit() {
    super.ngOnInit();

    let _self = this;
    
    document.querySelectorAll(".first-node-video-trigger").forEach(o => {
      o.addEventListener('click', (e) => {
        this.sharedService.fadeOutAndNavigateTo(["/video", _self.prefix, _self.swiper.activeIndex]);
      }, false);
    })
    
    document.querySelectorAll(".video-trigger").forEach(o => {
      o.addEventListener('click', (e) => {
        this.sharedService.fadeOutAndNavigateTo(["/video", _self.prefix, _self.swiper.activeIndex]);
      }, false);
    })

    document.querySelectorAll(".video-close").forEach(o => {
      o.addEventListener('click', (e) => {
        this.sharedService.markVideoAsSeen(_self.prefix, _self.swiper.activeIndex);
      }, false);
    });

  }

  ngAfterViewInit(): void {
    let _self = this;

    setTimeout(() => {
      _self.bodyElement.classList.add("fade-in");
    }, 100);

    this.swiper = new Swiper(`.swiper-container-${this.prefix}`, {
      on: {
        init: function () {
          _self.sharedService.lastIndex = this.activeIndex;
        },
        slideChange: function () {
          _self.sharedService.markNodeAsSeen(_self.params.prefix, _self.params.index);
          let translatedPath: any = _self.localize.translateRoute('/node');
          _self.router.navigate([translatedPath, _self.params.prefix, this.activeIndex], { replaceUrl: true });
        }
      },
      speed: 400,
      spaceBetween: 450,
      resistanceRatio: 0.7,
      shortSwipes: false,
      mousewheel: false,
      initialSlide: this.index,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }

  navigateToInternalNode(i: any) {
    this.swiper.slideTo(i, 1000);
  }

  navigateToOtherNodes(prefix, index) {
    this.sharedService.fadeOutAndNavigateTo(["/node", prefix, index]);
  }
}
