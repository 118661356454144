import { AfterViewInit, Component, OnInit } from '@angular/core';
import { IsComponent } from '../is/is.component';

@Component({
  selector: 'app-eo-is',
  templateUrl: './eo-is.component.html',
  styleUrls: ['./eo-is.component.css']
})
export class EoIsComponent extends IsComponent implements OnInit, AfterViewInit {

  cities = [
    {
      name: "Amsterdam",
      selector: "amsterdam"
    },
    {
      name: "Berlin",
      selector: "berlin"
    },
    {
      name: "Paris",
      selector: "paris"
    },
    {
      name: "Rome",
      selector: "rome"
    }
  ];

  currentSelector = "tutorial";
  currentView = "realistic";

  changeCity(selector: string) {
    this.currentSelector = selector;
    this.currentView = "realistic";
    document.getElementsByTagName("body")[0].classList.remove("show-aside");
  }

  changeView(view: string) {
    this.currentView = view;
  }

  ngAfterViewInit(): void {
    /*  let heightHeader = document.querySelector("header").offsetHeight;
     let wrapperElement: any = document.querySelector(".wrapper");
     let asideElement: any = document.querySelector("aside");
     let mainElement: any = document.querySelector("main");
     let height = document.querySelector("main").offsetHeight;
 
     wrapperElement.style.marginTop = heightHeader + "px";
     asideElement.style.height = (height - heightHeader) + "px";
     mainElement.style.height = (height - heightHeader) + "px"; */
  }

  get prefix(): string {
    return "eo-is";
  }

  showAside() {
    document.getElementsByTagName("body")[0].classList.add("show-aside");
  }

  hideAside() {
    document.getElementsByTagName("body")[0].classList.remove("show-aside");
  }

}
