import { Component, HostListener, OnInit } from "@angular/core";
import { PageComponent } from "../page/page.component";
import { Router } from "@angular/router";
import { SharedService } from "src/app/services/shared.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { selectors } from "./home";
import * as d3 from "d3";
import { Constants } from "src/app/constants";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent extends PageComponent implements OnInit {

    initialScale: number = 3;
    maxZoom: number = 2;
    zoom: { scaleBy: any; transform: any; };
    backgroundReady = false;
    pathsReady = false;
    x0;
    y0;
    x1;
    y1;
    zoomEnabled = true;
    currentTransform;
    zoomTick = 0;
    translFrom;
    translTo;
    backgroundImage = {
        en: './assets/images/background_en.jpg',
        fr: './assets/images/background_fr.jpg'
    }
    currentLang = 'en';

    isActive = false;

    get prefix(): string {
        return "home";
    }

    constructor(private router: Router, private sharedService: SharedService, private deviceService: DeviceDetectorService, private translateService: TranslateService) {
        super();
        this.currentLang = this.translateService.currentLang;
        this.translateService.onLangChange.subscribe(data => {
            this.currentLang = data.lang;
            setTimeout(() => {
                window.location.reload();
            }, 500);
        });
        this.sharedService.showLoader();

        this.isReady.subscribe(what => {
            if (what === 'paths') {
                this.pathsReady = true;
            }
            if (what === 'background') {
                this.backgroundReady = true;
            }
            if (this.pathsReady && this.backgroundReady) {
                this.sharedService.hideLoader();
            }
        })

        this.sharedService.subject.subscribe(event => {
            if (event.key === Constants.EVENT_ON_READY_LOADER) {
                this.afterLoader();
            }
        })
    }

    refreshWindow() {
        const innerWidth = window.innerWidth;
        const innerHeight = window.innerHeight;
        const backgroundSize = ((innerWidth > innerHeight) ? innerWidth : innerHeight) * this.initialScale;

        const imageWidth = backgroundSize;
        const imageHeight = backgroundSize;

        const svg = d3.select("svg").attr('width', imageWidth).attr('height', imageHeight);
        //.attr("x", +innerWidth / 2 - backgroundSize / 2).attr("y", +innerHeight / 2 - backgroundSize / 2)
        const g = svg.select("g").attr("cursor", "grab");
        //.attr("x", +innerWidth / 2 - backgroundSize / 2).attr("y", +innerHeight / 2 - backgroundSize / 2)
        const background = g.select("#background").attr('width', imageWidth).attr('height', imageHeight)
            .attr("x", +innerWidth / 2 - backgroundSize / 2)
            .attr("y", +innerHeight / 2 - backgroundSize / 2)
            .attr('onload', () => {
                this.isReady.next("background");
            });

        this.x0 = +background.attr("x");
        this.y0 = +background.attr("y");
        this.x1 = backgroundSize + this.x0;
        this.y1 = backgroundSize + this.y0;

        d3.select("#Livello_1").attr("x", this.x0).attr("y", this.y0);
        d3.selectAll(selectors.activePaths.join(',')).attr("x", this.x0).attr("y", this.y0)

        console.log([[this.x0, this.y0],[this.x1 - this.x0, this.y1 - this.y0]]);

        this.zoom = d3.zoom()
            .scaleExtent([Math.max(innerWidth / (this.x1 - this.x0), innerHeight / (this.y1 - this.y0)), this.maxZoom])
            .on("zoom", ({ transform }) => {
                let t = transform;

                if (this.zoomTick == 0) {

                    if (t.invertX(0) <= this.x0) {
                        //t.x = -this.x0 * t.k;
                    }
    
                    if (t.invertX(innerWidth) >= this.x1) {
                        //t.x = window.innerWidth - this.x1 * t.k;
                    }
    
                    if (t.invertY(0) <= this.y0) {
                        //t.y = -this.y0 * t.k;
                    }
    
                    if (t.invertY(innerHeight) >= this.y1) {
                        //t.y = innerHeight - this.y1 * t.k;
                    }

                } else {
                    if (this.translFrom) {
                        this.zoomTick += 0.01;
                        if (this.zoomTick > 1) this.zoomTick = 1;
                        t.x = this.lerp(this.translFrom.x, this.translTo.x, this.zoomTick);
                        t.y = this.lerp(this.translFrom.y, this.translTo.y, this.zoomTick);
                        t.k = this.lerp(this.translFrom.k, this.translTo.k, this.zoomTick);
                    }
                    
                }
                
                this.currentTransform = t;
                d3.select("g").attr("transform", t);

                //console.log("Zoom", this.currentTransform, this.zoomTick);
            });


        if (this.currentTransform) {
            d3.select("svg").call(this.zoom.transform, this.currentTransform).call(this.zoom);
        } else {
            d3.select("svg").call(this.zoom);
        }

    }

    refreshEvents() {
        this.setEvents(d3.select("svg"), innerWidth, innerHeight);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.bodyElement.classList.add("fade-in");

        // this.initMobile();

        this.refreshWindow();

        d3.xml("./assets/images/map.svg")
            .then((data: { documentElement: any; }) => {
                d3.select("#main-group").node().append(data.documentElement);
                let foreground = d3.select("#Livello_1");
                foreground.attr("x", this.x0).attr("y", this.y0);

                let startSelectors = [
                    selectors.sem.start,
                    selectors.poe.start,
                    selectors.ile.start,
                    selectors.ssa.start,
                    selectors.uou.start,
                    selectors.bbt.start
                ].join(',');

                d3.selectAll(startSelectors).on("click", (e: any) => {
                    let id = e.composedPath()[1].getAttribute('id');
                    let prefix = id.split("-")[0];
                    this.activatePath(prefix);
                });
            }).then(() => {

                let files = [
                    `./assets/i18n/${this.currentLang}/images/sem-active-path.svg`,
                    `./assets/i18n/${this.currentLang}/images/poe-active-path.svg`,
                    `./assets/i18n/${this.currentLang}/images/ile-active-path.svg`,
                    `./assets/i18n/${this.currentLang}/images/ssa-active-path.svg`,
                    `./assets/i18n/${this.currentLang}/images/uou-active-path.svg`,
                    `./assets/i18n/${this.currentLang}/images/bbt-active-path.svg`
                ]

                let promises = [];
                files.forEach(path => {
                    promises.push(d3.xml(path));
                });

                Promise.all(promises).then(function (svg) {
                    svg.forEach((data: { documentElement: any; }, index) => {
                        d3.select("#main-group").node().append(data.documentElement);
                    })
                }).then(() => {
                    d3.selectAll(selectors.activePaths.join(',')).attr("x", this.x0).attr("y", this.y0).style("display", "none").style("opacity", "0");

                    let activeSelectors = [
                        selectors.sem.active.join(','),
                        selectors.ssa.active.join(','),
                        selectors.poe.active.join(','),
                        selectors.ile.active.join(','),
                        selectors.uou.active.join(','),
                        selectors.bbt.active.join(',')
                    ].join(',');

                    d3.selectAll(
                        activeSelectors
                    ).on("click", (e: any) => {
                        let id = e.composedPath()[1].getAttribute('id');
                        let array = id.split("-");
                        let prefix = array[0];
                        let index = array.pop();
                        let point = {
                            x: e.x,
                            y: e.y
                        }
                        console.log("d3ClickNode");
                        this.clickOnNode(point, () => {
                            this.sharedService.lastPrefix = prefix;
                            this.sharedService.lastIndex = index;
                            this.sharedService.fadeOutAndNavigateTo(["/video", prefix, index]);
                        });
                    });

                    this.isReady.next("paths");
                }).catch(function (err) {
                })
            });

        //deactivate paths
        d3.select("svg").on("click", (e: any) => {
            let id = e.composedPath()[1].getAttribute('id');
            if (id === 'main-group' && this.isActive) {
                this.deactivate();
                return false;
            }
        });



        //this.refreshZoom();
        this.refreshEvents()
    }

    ngAfterViewInit(): void {
        if (!this.sharedService.firstLoad) {
            this.afterLoader();
        }
    }

    afterLoader() {
        setTimeout(() => {
            if (this.sharedService.lastIndex != -1) {
                setTimeout(() => {
                    this.activateAndZoom(this.sharedService.lastPrefix, this.sharedService.lastIndex);
                }, 1000);
            } else {
                if (this.deviceService.isDesktop()) {
                    this.zoomInAsync(4000);
                }
            }

            this.sharedService.homeVideo = false;
            d3.select("#DISCOVER_ESA_TITLE").on("click", () => {
                this.sharedService.lastIndex = -1;
                this.sharedService.lastPrefix = "";
                this.sharedService.homeVideo = true;
                this.sharedService.fadeOutAndNavigateTo(["/video", "home", 0]);
            });
        }, 500);
    }

    initMobile() {
        if (this.deviceService.isMobile()) {
            this.maxZoom = 4;
        }
    }

    activateAndZoom(prefix: String, index: number) {
        console.log("ActivateAndZoom");
        this.activatePath(prefix);
        this.zoomNode(`#${prefix}-empty-node-${index} path`);
    }

    zoomOutAsync(svg: any, ms: number) {
        console.log("zoomOutAsync");
        setTimeout(() => {
            svg.transition().duration(ms).ease(d3.easeBackOut.overshoot(5)).call(this.zoom.scaleBy, this.maxZoom, [window.innerWidth / 2, window.innerHeight / 2]);
        }, 2000);
    }

    zoomInAsync(ms: number) {
        console.log("Zoom In Async");
        d3.select("svg").transition().duration(ms).call(this.zoom.scaleBy, 1 / this.initialScale, [window.innerWidth / 2, window.innerHeight / 2]);
    }

    setEvents(svg: any, innerWidth: number, innerHeight: number) {
        console.log("setEvents");
        Object.assign(svg.node(), {
            zoomIn: () => {
                svg.transition().duration(1000).call(this.zoom.scaleBy, 1.5, [innerWidth / 2, innerHeight / 2]);
            },
            zoomOut: () => {
                svg.transition().duration(1000).call(this.zoom.scaleBy, 0.5, [innerWidth / 2, innerHeight / 2]);
            }
        });
    }


    reset() {
        this.zoomNode("#DISCOVER_ESA_TITLE", 1);
    }

    deactivate() {
        console.log("deactivate");
        let activePathSelectors = selectors.activePaths.join(',');
        let emptyPathSelectors = selectors.emptyPaths.join(',');
        d3.selectAll(activePathSelectors).transition().duration(1500).ease(d3.easeLinear).style("opacity", 0).on("end", () => {
            setTimeout(() => {
                d3.selectAll(activePathSelectors).style("display", "none");
            }, 500);
        });
        d3.selectAll(emptyPathSelectors).transition().duration(1500).ease(d3.easeLinear).style("opacity", 1);

        this.reset();

        this.sharedService.lastPrefix = "";
        this.sharedService.lastIndex = -1;

        this.isActive = false;
    }

    activatePath(prefix) {
        console.log("Activate Path - percorso");

        this.isActive = true;
        d3.selectAll(`#${prefix}-active-path`).transition().duration(1500).ease(d3.easeLinear).style("display", "unset").style("opacity", 1);
        d3.selectAll(`#${prefix}-empty-path`).transition().duration(1500).ease(d3.easeLinear).style("display", "unset").style("opacity", 1);
        let activePathSelectors = selectors.activePaths.filter((o) => o != `#${prefix}-active-path`).join(',');
        let emptyPathSelectors = selectors.emptyPaths.filter((o) => o != `#${prefix}-empty-path`).join(',');
        d3.selectAll(emptyPathSelectors).transition().duration(1500).ease(d3.easeLinear).style("opacity", .5);
        d3.selectAll(activePathSelectors).transition().duration(1500).ease(d3.easeLinear).style("opacity", 0).style("display", "none");
        this.zoomNode(`#${prefix}-empty-node-0`, this.maxZoom / 2);
        this.sharedService.lastPrefix = prefix;
    }

    clickOnNode(point: any, callback?) {
        console.log("ClickOnNode");
        let matrix = [0, 0];
        let scale = 1;

        const node = d3.select("g").node();
        if (node.transform.baseVal[0]) {
            let matrixTemp = node.transform.baseVal[0].matrix;
            matrix = [matrixTemp.e, matrixTemp.f];
            scale = node.transform.baseVal[1].matrix.a;
        }

        let m = 0;
        if (scale == 1) {
            m = this.maxZoom;
        } else if (scale == this.maxZoom) {
            m = 1;
        } else {
            m = this.maxZoom / scale;
        }

        const newX = (innerWidth / 2 - point.x * m) + (matrix[0] * m);
        const newY = (innerHeight / 2 - point.y * m) + (matrix[1] * m);

        let transform = d3.zoomIdentity.translate([0, 0]);

        if (!transform.x) {
            transform.x = newX;
        }
        if (!transform.y) {
            transform.y = newY;
        }

        transform.k = this.maxZoom;
        this.zoomTick = 0.01;
        this.translFrom = this.currentTransform;
        this.translTo = transform;

        d3.select("svg").transition().duration(1000).ease(d3.easeLinear).call(this.zoom.transform, transform).on("end", () => {
            this.zoomTick = 0;
            if (callback && typeof (callback) === 'function') {
                callback();
            }
        });
    }

    lerp (a, b, t) {
        return (1 - t) * a + t * b;
    }

    zoomNode(selector: string, scale?: number) {

        let z = (scale) ? scale : this.maxZoom;
        console.log("selector ", selector);
        console.log("scale ", scale);
        const getBoundingBoxCenter = (selection: { node: () => any; }) => {
            var element = selection.node();
            var bbox = element.getBBox();
            return [bbox.x + bbox.width / 2, bbox.y + bbox.height / 2];
        }

        let bbCenter = getBoundingBoxCenter(d3.select(selector));
        let x = bbCenter[0];
        let y = bbCenter[1];
        let imageSize = 7000;

        let w = d3.select("image").attr('width');
        let h = d3.select("image").attr('height');

        // Riduce un po w e h per meglio centrare il nodo
        w = 5800;
        h = 5800;
        var xto = (w / 2 - w * x * z / imageSize);
        var yto = (h / 2 - h * y * z / imageSize);

        console.log(this.currentTransform);

        this.translFrom = this.currentTransform;
        this.zoomTick = 0.001;
        
        let transform = d3.zoomIdentity.translate([xto, yto]);

        if (!transform.x) {
            transform.x = (w / 2 - w * x / imageSize);
        }

        if (!transform.y) {
            transform.y = (h / 2 - h * y / imageSize);
        }
        transform.z = 2;
        console.log(transform);
        this.translTo = transform;

        d3.select("svg")
            .transition()
            .duration(2000)
            .ease(d3.easeLinear)
            .call(this.zoom.transform, transform).on("end", () => {
                this.zoomTick = 0;
                // d3.select("svg").transition().duration(1000).call(this.zoom.scaleBy, z, [innerWidth / 2, innerHeight / 2]);                
            });
        
    }

    zoomIn() {
        d3.select("svg").node().zoomIn();
        console.log("ZoomIn");
    }

    zoomOut() {
        d3.select("svg").node().zoomOut();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any): void {
        d3.select("svg").on('.zoom', null);
        this.refreshWindow();
        this.refreshEvents();
    }
}