import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Constants } from 'src/app/constants';
import { SharedService } from 'src/app/services/shared.service';
import { PageComponent } from '../page/page.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent extends PageComponent implements OnInit, AfterViewInit {

  params;
  hasMultiLanguage: string = "";

  get prefix(): string {
    return "video";
  }

  constructor(private route: ActivatedRoute, private sharedService: SharedService, private translate: TranslateService) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.route.params.subscribe((params: Params) => {
      this.hasMultiLanguage = params.prefix === "home"? '' : `${this.translate.currentLang}`;
      this.params = params;
      if (!this.sharedService.homeVideo) {
        this.sharedService.markVideoAsSeen(this.params.prefix, this.params.index);
      }
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.bodyElement.classList.add("fade-in");
    }, 100);
  }

  onEnded() {
    this.sharedService.subject.next({
      key: Constants.EVENT_ON_VIDEO_ENDED,
      data: null
    });
  }

}
