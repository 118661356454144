<div class="wrapper">
    <aside>
        <div class="cities">
            <ul>
                <li *ngFor="let city of cities" class="city-selector city-selector-{{city.selector}} active" (click)="changeCity(city.selector)">{{city.name}}</li>
            </ul>
        </div>
    </aside>
    <main>
        <div class="city city-tutorial" [class.current]="currentSelector == 'tutorial'">
            <div class="city-img city-img-normal" [class.show]="currentSelector == 'tutorial'" style="background-image: url(assets/images/eo-is/cities/IS_cities_cover.jpg)">
                <h5 [innerHTML]="'eo-is.select' | translate"></h5>
            </div>
        </div>
        <div *ngFor="let city of cities" [class.current]="city.selector === currentSelector" class="city city-{{city.selector}}">
            <div class="city-img city-img-normal" [class.show]="city.selector === currentSelector" style="background-image: url(assets/images/eo-is/cities/{{city.selector}}/{{currentView}}.jpg)" (click)="hideAside()">
                <div class="details">
                    <p>
                        <span class="label">City </span> <span class="city-name">{{city.name}} </span>
                        <span *ngIf="currentView === 's1';else s2ViewTemplate">
                            <span class="label" *ngIf="'s1' === currentView" [innerHTML]="'eo-is.view' | translate"></span>
                            <span class="sat-name" *ngIf="'s1' === currentView">Sentinel-1</span>
                        </span>
                        <ng-template #s2ViewTemplate>
                            <span class="label" *ngIf="'s2' === currentView" [innerHTML]="'eo-is.view' | translate"></span>
                            <span class="sat-name" *ngIf="'s2' === currentView">Sentinel-2</span>
                        </ng-template>                        
                    </p>
                    <p>
                        <span *ngIf="currentView === 's1';else s2CaptionTemplate" class="caption sentinel-1">{{'eo-is.s1-caption' | translate}}</span>
                        <ng-template #s2CaptionTemplate>
                            <span class="caption sentinel-2" >{{'eo-is.s2-caption' | translate}}</span>
                        </ng-template>
                    </p>
                </div>
            </div>
            <div class="aside-trigger">
                <div class="aside-trigger-tool" (click)="showAside()">
                    <img src="assets/icons/city.svg">
                    <span [innerHTML]="'eo-is.cities' | translate"></span>
                </div>
            </div>
            <div class="sats">
                <ul>
                    <li class="sat-selector sat-selector-normal" [class.active]="'realistic' === currentView" (click)="changeView('realistic')">
                        <img src="assets/images/eo-is/satellites/realistic.png">
                        <span [innerHTML]="'eo-is.your-eyes' | translate"></span>
                    </li>
                    <li class="sat-selector sat-selector-s1" [class.active]="'s1' === currentView" (click)="changeView('s1')">
                        <img src="assets/images/eo-is/satellites/s1.png">
                        <br> Sentinel-1
                    </li>
                    <li class="sat-selector sat-selector-s2" [class.active]="'s2' === currentView" (click)="changeView('s2')">
                        <img src="assets/images/eo-is/satellites/s2.png">
                        <br> Sentinel-2
                    </li>
                </ul>
            </div>
        </div>
    </main>
</div>