import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PolicyService } from 'src/app/services/policy.service';

@Component({
  selector: 'app-policy-bar',
  templateUrl: './policy-bar.component.html',
  styleUrls: ['./policy-bar.component.css']
})
export class PolicyBarComponent implements OnInit {

  policyValidated :boolean = false;
  private policySubscription :Subscription;
  checkPolicyChoise :boolean = false;

  constructor(private router : Router, private policyService : PolicyService) { }


  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      event instanceof NavigationEnd? 
        this.policySubscription = this.policyService.policyMsg.subscribe( result =>  this.checkPolicyChoise = this.policyService.checkPolicyChoise("PolicyBarComponent")) : null;
    });
  }

  ngOnDestroy(){
    try {
      this.policySubscription? this.policySubscription.unsubscribe() : null;
    } catch (error) {
      console.log(error);
    }
  }

}
