import {
    trigger,
    transition,
    style,
    query,
    group,
    animateChild,
    animate,
    keyframes,
} from '@angular/animations';

export const fadeInAnimation = trigger('routeAnimations', [
    /*transition('* => *', [
        style({ 
          opacity : 0
        }),
        animate(1000, style({ 
          opacity : 1
        }))
    ]),
    transition('* => *', [
      style({ 
        opacity : 1
      }),
      animate(1000, style({ opacity: 0 }))
    ])*/
]);