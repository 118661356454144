<div class="language-modal-container">
  <div class="language-modal-loader" id="language-selector">
    <div class="language-close" (click)="close()"> <img src="assets/icons/close-white.svg"></div>
    <div class="language-modal-contents">
      <!-- <h4>{{'loader.title' | translate}}</h4> -->
      <h5>{{'footer.select-language' | translate | uppercase }}</h5>
      <div class="lang-list">
        <div *ngFor="let lang of langList" class="lang-item" [ngClass]="{'active': lang === currentLang}" (click)="changeLang(lang)">
          <img [src]="'assets/icons/flag-' + lang + '.svg'">
        </div>
      </div>
      <p class="error-message" *ngIf="error">
        {{'footer.error-message' | translate}}
      </p>
      <p class="success-message" *ngIf="success">
        {{'footer.success-message' | translate}}
      </p>
    </div>
  </div>