<div *ngIf="qrUrl !== ''" class="language-modal-container">
  <div class="language-modal-loader" id="language-selector">
    <div class="language-close" (click)="closeQrModal()"> <img src="assets/icons/close-white.svg"></div>
    <div class="language-modal-contents">
      <!-- <h4>{{'loader.title' | translate}}</h4> -->
      <h5>{{'qr-code.text' | translate}}</h5>
      <qrcode [qrdata]="qrUrl" [width]="256" [errorCorrectionLevel]="'M'" [colorLight]="'#003247'"
        [colorDark]="'#ffffff'" [elementType]="'canvas'" [imageSrc]="'./assets/images/ariane6-is/ariane6-bg.jpg'"
        [imageHeight]="75" [imageWidth]="75"></qrcode>
    </div>
  </div>
</div>
